import Sprite from './data/Sprite'

export default class Dot extends Sprite {
  constructor(note, depth) {
    super()
    this.note = note
    this.depth = depth
    this.bullets = []
    for (let i = 0; i < depth + 1; i++) {
      const bullet = new Sprite()
      bullet.id = `dot-${i}`
      this.bullets.push(bullet)
    }
  }

  evalLayout(styles) {
    let width = styles.dot.gutter
    width += styles.dot.radius * 2 * this.bullets.length
    width += styles.dot.spacer * this.depth
    this.set('measuredWidth', width)
    this.set('radius', styles.dot.radius)
  }

  layoutCrossAxis(styles) {
    const staffUnit = styles.staff.unit
    const headLineIndex = this.note.head.lineIndex.absolute
    let dotTop
    if (this.note.head.isRest) {
      dotTop = styles.staff.middle - staffUnit / 2
    } else {
      dotTop = this.note.head.y
      const onLine = Math.floor(headLineIndex) === headLineIndex
      if (!onLine) dotTop += staffUnit / 2
    }
    this.set('y', Math.floor(dotTop) - 1)
  }

  layoutMainAxis(posX, styles) {
    posX += styles.dot.gutter
    this.set('x', posX)
    this.set('width', this.measuredWidth)
    let bulletX = 0
    this.bullets.forEach((bullet, bulletIndex) => {
      if (bulletIndex > 0) {
        bulletX += styles.dot.spacer
      }
      bullet.set('x', bulletX)
      bulletX += styles.dot.radius * 2
    })
  }

  render() {
    return {
      png: 'dot',
      transform: this.translate,
      bullets: this.bullets.map((bullet, bulletIndex) => ({
        id: `flag-${this.note.code}-${bulletIndex}`,
        transform: bullet.translate,
        x: this.x + bullet.x,
        y: this.y || 0,
        key: bullet.id
      })),
      radius: this.radius
    }
  }
}

import Sprite from './data/Sprite'

export default class Clef extends Sprite {
  constructor(track) {
    super()
    this.track = track
    this.svg = '#clef-treble'
    this.png = 'clef-treble'
  }

  evalLayout(styles) {
    this.set('measuredWidth', styles.clef.treble.width)
    this.set('measuredHeight', styles.clef.treble.height)
    const clefTop = -2 * styles.staff.unit
    this.track.updateOverflow(clefTop, this.measuredHeight)
  }

  layoutCrossAxis(styles) {
    const clefTop = -2 * styles.staff.unit
    this.set('y', clefTop)
    this.set('height', this.measuredHeight)
  }

  layoutMainAxis(beforeX) {
    this.set('x', beforeX)
    this.set('width', this.measuredWidth)
  }
  render() {
    return {
      id: `clef-${this.track.code}`,
      x: this.x,
      y: this.y,
      svg: this.svg,
      png: this.png
    }
  }
}

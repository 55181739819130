/**
 * Created in : 'sheet-builder'
 * Ancestors : 'Fragment', 'Beat'
 * Children : 'Context'
 */
export default class Event extends Array {
  constructor() {
    super()
    // this.index = NaN
    // this.tempoBase = NaN
    this.tempo = NaN
    //
    // Timestamp
    //
    this.duration = null
    this.position = null
    //
    // Ancestors
    //
    this.beatIndex = NaN
    this.beatIndexInFragment = NaN
    this.beat = null
    this.fragment = null
    this.fragmentIndex = NaN
    //
    // Flags
    //
    this.onBeat = false
    this.onFragment = false
  }
}

import Sprite from './data/Sprite'
import { regexSettings } from '../settings/SheetSettings.js'

/**
 * pitch : letter from 'a' to 'g'
 * pitchIndex : index from 0 ('c') to 7 ('b')
 * halfTone : number of half tone from 0 ('c') to 11 ('b')
 * lineIndex : index of line from ('c') to 3.5 ('b')
 */

const pitchIndexToHalfTone = [0, 2, 4, 5, 7, 9, 11]
const halfToneToPitchIndex = [0, 0, 1, 1, 2, 3, 3, 4, 4, 5, 5, 6]

const pitchIndexToPitch = (p) => String.fromCharCode(((p + 2) % 7) + 97)

// const noteslist = ['c','c#','d','d#','e','f','f#','g','g#','a','a#','b']

export default class Head extends Sprite {
  /**
   *
   */
  static lineIndexFromPitch(pitch, octava) {
    let lineIndex = (pitch.charCodeAt(0) - 99) / 2
    if (lineIndex < 0) {
      lineIndex = 3.5 + lineIndex
    }
    return {
      relative: lineIndex,
      absolute: octava * 3.5 + lineIndex
    }
  }
  /**
   *
   */
  constructor(note, currentHead) {
    super()
    const reg = regexSettings.note
    const match = note.raw.match(reg)
    const tune = note.track.measure.tune
    if (!match) {
      throw new Error(`Raw note doesn't match: ${note.raw}`)
    } else {
      let pitch = match[1] || 'c'
      const isRest = pitch === '*' || pitch === 'r'
      if (!isRest) {
        let accidental = match[2] || null
        let offset = match[3] || ''
        let octava = currentHead?.baseOctava || 4
        if (octava < 0) octava = 4
        let alt = 0
        if (accidental === '#') alt = 1
        if (accidental === 'b') alt = -1
        if (accidental === '!') alt = 0
        //
        // Eval lineIndex
        //
        let lineIndex = Head.lineIndexFromPitch(pitch, octava)
        //
        // Eval the octava offset
        //
        if (!isNaN(offset) && offset != '') {
          octava = Number(offset)
          lineIndex = Head.lineIndexFromPitch(pitch, octava)
        } else {
          const reducer = (a, v) => (v === '↓' ? a - 1 : a + 1)
          offset = Array.from(offset).reduce(reducer, 0)
          if (currentHead) {
            const gap = lineIndex.relative - currentHead.baseLineIndex.relative
            if (Math.abs(gap) > 1.5) {
              offset -= Math.sign(gap)
            }
          }
          if (offset != 0) {
            octava += offset
            lineIndex.absolute = octava * 3.5 + lineIndex.relative
          }
        }
        //
        // Eval absolute alt : With tune and tack alts
        //
        // - First we use the accident of the note
        // - If note has no accident we use alterations of the track
        // - If still no alteration we use alterations of the tune
        //
        const trackAlts = note.track.alts
        let absAlt = alt
        if (!accidental) {
          if (trackAlts.has(pitch)) {
            absAlt = trackAlts.get(pitch)
          } else if (tune.base.altPitches.has(pitch)) {
            absAlt = tune.base.alt
          }
        } else {
          trackAlts.set(pitch, alt)
        }
        const transpose = tune.transpose
        this.basePitch = pitch
        this.baseOctava = octava
        this.baseLineIndex = lineIndex
        this.baseAccidental = accidental
        if (transpose) {
          //
          // Transposition
          //
          const direction = Math.sign(transpose)
          const transTones = Math.abs(transpose)
          let pitchIndex = (pitch.charCodeAt(0) - 92) % 7
          let halfTones = pitchIndexToHalfTone[pitchIndex] + absAlt
          for (var i = 0; i < transTones; i++) {
            halfTones += direction
            if (halfTones > 11) {
              halfTones = 0
              octava++
            } else if (halfTones < 0) {
              halfTones = 11
              octava--
            }
          }
          pitchIndex = halfToneToPitchIndex[halfTones]
          absAlt = halfTones != pitchIndexToHalfTone[pitchIndex] ? 1 : 0
          pitch = pitchIndexToPitch(pitchIndex)
          //
          // Check to use flat instead of sharp
          //
          if (absAlt && absAlt != tune.alt) {
            const upPitchIndex = (pitchIndex + 1) % 7
            const upPitch = pitchIndexToPitch(upPitchIndex)
            if (tune.altPitches.has(upPitch)) {
              absAlt = -1
              pitch = upPitch
              if (!upPitchIndex) octava++
            }
          }
          //
          // Check the current alt for pitch frome tune and track, to eval the
          // accidental of the note
          //
          let currentAlt = 0
          if (tune.altPitches.has(pitch)) currentAlt = tune.alt
          else if (trackAlts.has(pitch)) currentAlt = trackAlts.get(pitch)
          if (currentAlt != absAlt) {
            if (absAlt) {
              accidental = absAlt > 0 ? '#' : 'b'
            } else {
              accidental = '!'
            }
          } else {
            accidental = ''
          }
          lineIndex = Head.lineIndexFromPitch(pitch, octava)
        }
        //
        // Set object
        //
        this.pitch = pitch
        this.octava = octava
        this.lineIndex = lineIndex
        this.accidental = accidental
        //
        // Create midi info. Convert flat to sharp. in this case the pitch
        // is lowered
        //
        let midiPitch = pitch
        let midiOctava = octava
        let midiAccidental = absAlt != 0 ? '#' : ''
        if (absAlt < 0) {
          let pitchCharCode = pitch.charCodeAt(0) - 1
          if (pitchCharCode === 96) pitchCharCode = 103
          if (pitchCharCode === 98) midiOctava--
          if (pitch === 'c' || pitch === 'f') midiAccidental = ''
          midiPitch = String.fromCharCode(pitchCharCode)
        }
        this.midi = {
          pitch: midiPitch.toUpperCase(),
          accidental: midiAccidental,
          octava: midiOctava
        }
      } else {
        this.isRest = true
        this.lineIndex = Head.lineIndexFromPitch('g', 4)
      }
      //
      // Evaluate head's shape
      //
      const duration = note.duration.metric.valueOf()
      this.shape = 'quarter'
      if (duration >= 4) {
        this.shape = 'whole'
      } else if (duration >= 2) {
        this.shape = 'half'
      } else if (duration < 1 && this.isRest) {
        let coeff = 4
        let metric = note.duration.metric.d
        if (note.dot) {
          metric /= (note.dot.depth + 1) * 2
        }
        this.shape = `${metric * coeff}th`
      }
      if (!this.isRest) {
        this.png = `head-${this.shape}`
      } else {
        if (note.duration.measure.valueOf() === 1) {
          this.png = 'rest-whole'
        } else {
          this.png = `rest-${this.shape}`
        }
      }
      this.svg = `#${this.png}`
      this.note = note
    }
  }

  evalLayout(styles) {
    let headSize
    if (this.isRest) {
      headSize = styles.rest[this.shape]
    } else {
      headSize = styles.head[this.shape]
    }
    this.measuredWidth = headSize.width
    this.measuredHeight = headSize.height
  }

  layoutCrossAxis(styles) {
    const headLineIndex = this.lineIndex.absolute
    let y = 0
    if (!this.isRest) {
      if (this.accidental) {
        this.accidental
      }
      y = styles.staff.top - (headLineIndex + 0.5) * styles.staff.unit
    }
    this.set('y', y, true)
    this.set('height', this.measuredHeight)
    this.note.track.updateOverflow(y, this.measuredHeight)
  }

  layoutMainAxis(posX) {
    this.set('x', posX)
    this.set('width', this.measuredWidth)
  }

  render() {
    return {
      id: `head-${this.note.code}`,
      png: this.png,
      svg: this.svg,
      x: this.x,
      y: this.y
    }
  }
}

/**
 * Created in : 'sheet-builder'
 * Ancestors : 'Event'
 */
export default class Context {
  constructor() {
    this.depth = NaN
    this.flowIndex = NaN
    this.name = undefined
    this.nameIndex = NaN
    this.raw = undefined
    //
    // Timestamp
    //
    this.duration = null
    //
    // Ancestor
    //
    this.event = null
  }
}

import Sprite from './data/Sprite'

export default class Anchor extends Sprite {
  constructor(lyric) {
    super()
    this.lyric = lyric
    if (lyric.rawAnchor === '~') this.isSimple = true
    if (lyric.rawAnchor === '-') this.isStrong = true
    else this.isExtended = true
  }

  layoutCrossAxis(styles) {
    if (this.isSimple || this.isStrong) {
      this.set(
        'y',
        this.lyric.measuredHeight / 2 + styles.anchor.thickness,
        true
      )
    } else {
      this.set('y', this.lyric.measuredHeight - styles.anchor.bottom)
    }
    this.set('thickness', styles.anchor.thickness)
  }

  layoutMainAxis(styles) {
    let x
    const halfWidth = this.lyric.text.width + this.lyric.text.x
    const extwidth = this.lyric.extendedWidth || 0
    let width = this.lyric.width - halfWidth - extwidth
    if (this.isSimple) {
      x = (width - styles.anchor.articulation) / 2
      width = styles.anchor.articulation
    } else {
      x = styles.anchor.gutter
      if (this.isStrong) {
        width -= styles.anchor.gutter * 2
      } else {
        width += styles.head.quarter.width - x * 3
      }
      width = Math.max(0, width)
    }
    this.set('x', halfWidth + x)
    this.set('width', width)
  }

  render() {
    return {
      id: `anchor-${this.lyric.code}`,
      png: 'anchor',
      x: this.x,
      y: this.y,
      width: this.width,
      thickness: this.thickness
    }
  }
}

const tuneTones = [
  { key: 'c', order: 0, alt: 0, pitches: [] },
  { key: 'db', order: -5, alt: -1, pitches: ['b', 'e', 'a', 'd', 'g'] },
  { key: 'd', order: 2, alt: 1, pitches: ['f', 'c'] },
  { key: 'eb', order: -3, alt: -1, pitches: ['b', 'e', 'a'] },
  { key: 'e', order: 4, alt: 1, pitches: ['f', 'c', 'g', 'd'] },
  { key: 'f', order: -1, alt: -1, pitches: ['b'] },
  { key: 'gb', order: -6, alt: -1, pitches: ['b', 'e', 'a', 'd', 'g', 'c'] },
  { key: 'g', order: 1, alt: 1, pitches: ['f'] },
  { key: 'ab', order: -4, alt: -1, pitches: ['b', 'e', 'a', 'd'] },
  { key: 'a', order: 3, alt: 1, pitches: ['f', 'c', 'g'] },
  { key: 'bb', order: -2, alt: -1, pitches: ['b', 'e'] },
  { key: 'b', order: 5, alt: 1, pitches: ['f', 'c', 'g', 'd', 'a'] }
]

const tuneTonesMap = new Map(
  tuneTones.map((v, i) => {
    return [v.key, { index: i, ...v }]
  })
)

export default class Tune {
  constructor(raw, transpose) {
    this.raw = raw
    this.transpose = transpose
    const base = tuneTonesMap.get(raw) || tuneTonesMap.get('c')
    let index = (base.index + transpose) % tuneTones.length
    if (index < 0) index += 12
    const info = tuneTones[index]
    Object.assign(this, info)
    this.base = { altPitches: new Set(base.pitches), ...base }
    this.altPitches = new Set(info.pitches)
    if (info.alt > 0) this.altName = 'sharp'
    else if (info.alt < 0) this.altName = 'flat'
    else this.altName = 'neutral'
  }
}

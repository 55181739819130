import { Event, Fragment, Beat, Context, Group } from '../models'
import { nextTick } from '../../../jelly-flux/src//utils'

import Fraction from 'fraction.js'
// import clone from 'lodash/clone'

export default async ({ flows, chars, report }) => {
  const tickDate = new Date()
  const eventByPosition = new Map()
  const events = []
  const fragments = []
  const beats = []

  let fragment, currentBeat
  //
  // Tempo settings
  //
  const tempoMap = [{ pos: 0, base: 120, value: 120 }]
  //
  // STEP 1 : Build events, fragments and beats -------------------------------
  //
  // Warning : At this step the events aren't ordered. As they are created
  // flow by flow, if an event exists only in the n+x flow, it will be added
  // after the last event. That is why the list is ordere at step 2 and some
  // properties are setted only in step 3.
  //
  let flowIndex = 0
  const contextCounter = {}
  const voices = []
  report.voices = voices
  for await (const flow of flows) {
    const contextSymbol = flow.context.symbol
    const contextName = flow.context.name
    const isLyric = contextName === 'lyric'
    const isChord = contextName === 'chord'
    const isTempo = contextName === 'tempo'
    const isPart = contextName === 'part'
    const isNote = contextName === 'note'
    //
    // Grab infos
    //
    if (isLyric) report.hasLyric = true
    if (isChord) report.hasHarmony = true
    if (isPart) report.hasPart = true
    if (isNote) report.hasNote = true
    if (flowIndex === 0) {
      report.duration.fragment = flow.divisions.length
      if (flow.divisions.length) {
        report.hasMetric =
          flow.divisions.length > 1 || flow.divisions[0].length <= 12
      }
    }
    if (isNaN(contextCounter[contextSymbol])) {
      contextCounter[contextSymbol] = 0
    } else {
      contextCounter[contextSymbol]++
    }
    if (isLyric || isNote) {
      let voice = voices[voices.length - 1]
      if (!voice || voice.has(contextName)) {
        voice = new Set()
        voices.push(voice)
      }
      voice.add(contextName)
    }
    //
    // Recursive division inspector
    //
    // flowdiv : Division from flow model
    // depth : 0 => fragment
    //         1 => beat
    //         n => subbeat
    // parent : parent (beat or subbeat)
    //
    const evalDivision = (flowDiv, position, duration, depth, group) => {
      //
      // Eval metric and tuplet
      //
      const slotCount = flowDiv.length
      let metricCount = slotCount

      if (fragment.metric.isCompound) {
        metricCount *= 2
      }
      let secure = 0
      if (depth > 0) {
        metricCount = 1
        while (metricCount > 0) {
          let x = Math.pow(2, metricCount)
          if (x > slotCount) break
          else metricCount = x
          if (secure++ > 1000) throw new Error('WTF ! Infinite loop')
        }
        if (group && metricCount != slotCount) {
          group.tuplet = new Fraction(slotCount, metricCount)
        }
      }
      //
      // Loop through each division slot
      //
      flowDiv.forEach((divItem, divIndex) => {
        //
        // Extended division ('>') are ignored since they are taken into
        // account by previous item
        //
        if (divItem.raw?.startsWith(chars.extends)) {
          return
        }
        //
        // Eval length (number of slot occupied by an item)
        //
        let itemLength = 1
        while (flowDiv[divIndex + 1]?.raw?.startsWith(chars.extends)) {
          itemLength++
          divIndex++
        }
        //
        // Eval duration and position of the item
        //
        const itemDuration = {
          measure: duration.measure.div(slotCount).mul(itemLength),
          beat: duration.beat.div(slotCount).mul(itemLength),
          metric: duration.metric.div(metricCount).mul(itemLength)
        }
        const itemPosition = {
          measure: position.measure.clone(),
          beat: position.beat.clone()
        }
        //
        // Eval beat
        //
        const beatIndex = Math.floor(itemPosition.beat.valueOf())
        for (var i = 0; i < itemLength; i++) {
          let beat = beats[beatIndex + i]
          if (!beat) {
            // Depth should be 0
            beat = new Beat()
            beat.index = beatIndex + i
            beats.push(beat)
            fragment.push(beat)
          }
        }
        currentBeat = beats[beatIndex]
        //
        // Eval item (can be (sub)Division or Slot)
        //
        if (!divItem.raw) {
          //
          // Group creation
          //
          let divGroup = group
          divGroup = new Group()
          divGroup.depth = (group && group.depth + 1) || 0
          if (group) {
            divGroup.parent = group
            group.push(divGroup)
          }
          depth++
          //
          // Recurse sub division
          //
          evalDivision(divItem, itemPosition, itemDuration, depth, divGroup)
        } else {
          //
          // DivItem is slot
          //
          const slot = divItem
          //
          // Update the tempo Map
          //
          if (isTempo && slot.raw != chars.empty) {
            var newTempo = Number(slot.raw)
            tempoMap.push({
              pos: itemPosition.measure.valueOf(),
              value: newTempo
            })
          }
          //
          // Get or create the event
          //
          const positionId = itemPosition.measure.toFraction()
          let event = eventByPosition.get(positionId)
          if (!event) {
            //
            // Get the tempo
            //
            while (
              tempoMapIndex < tempoMap.length - 1 &&
              tempoMap[tempoMapIndex + 1].pos <= itemPosition.measure.valueOf()
            ) {
              tempoMapIndex++
            }
            let tempo = tempoMap[tempoMapIndex]
            const beatCount = fragment.beatCount
            const beat = itemPosition.measure.sub(fragment.index)
            const beatRatio = beat.valueOf() * beatCount
            const beatIndexInFragment = Math.floor(beatRatio)
            const onBeat = beatRatio === beatIndexInFragment
            event = new Event()
            event.position = itemPosition
            event.tempo = tempo.value
            event.fragment = fragment
            event.fragmentIndex = fragment.index
            event.beat = currentBeat
            event.beatIndexInFragment = beatIndexInFragment
            event.beatIndex = beatIndex
            event.onBeat = onBeat
            event.onFragment = onBeat && beatIndexInFragment === 0
            currentBeat.push(event)
            events.push(event)
            eventByPosition.set(positionId, event)
          }
          //
          // Add the slot as context to the event
          //
          const context = new Context()
          context.symbol = contextSymbol
          context.name = contextName
          context.nameIndex = contextCounter[contextSymbol]
          context.flowIndex = flowIndex
          context.duration = itemDuration
          context.raw = slot.raw
          context.rawPos = slot.rawPos
          context.event = event
          context.depth = depth
          if (isChord) {
            event.chord = slot.raw
          }
          if (slot.breakline) {
            context.breakline = true
          }
          if (group) {
            context.group = group
            group.push(context)
          }
          event.push(context)
        }
        //
        // Update position pointer
        //
        position.measure = position.measure.add(itemDuration.measure)
        position.beat = position.beat.add(itemDuration.beat)
      })
    }
    //
    // Check each root division
    //
    let beatIndex = 0
    let tempoMapIndex = 0
    let lastFragment
    if (!fragments.length) {
      flow.divisions.forEach((division, divisionIndex) => {
        fragment = new Fragment(fragments, division.length)
        if (
          divisionIndex === 1 &&
          lastFragment.beatCount != fragment.beatCount &&
          fragment.metric.isCompound
        ) {
          lastFragment.metric = fragment.metric
        }
        lastFragment = fragment
      })
    }
    flow.divisions.forEach((division, divisionIndex) => {
      const position = {
        measure: new Fraction(divisionIndex),
        beat: new Fraction(beatIndex)
      }
      const duration = {
        measure: new Fraction(1),
        beat: new Fraction(division.length),
        metric: new Fraction(division.length)
      }
      //
      // Create new fragment
      //
      fragment = fragments[divisionIndex]
      //
      // Eval the division
      //
      evalDivision(division, position, duration, 0)
      beatIndex += division.length
      //
      // Update infos (beat count)
      //
      if (flowIndex === 0) {
        report.duration.beat += division.length
      }
    })
    flowIndex++
    await nextTick('SHEET BUILDER STEP 1.' + flowIndex, tickDate)
  }
  //
  // STEP 2 : Sort events var position -------------------------------------
  //
  const sortFunc = (a, b) => {
    return a.position.measure.valueOf() - b.position.measure.valueOf()
  }
  events.sort(sortFunc)
  fragments.forEach((fragment) => {
    fragment.forEach((beat) => {
      beat.sort(sortFunc)
    })
  })
  await nextTick('SHEET BUILDER STEP 2', tickDate)
  //
  // STEP 3 : Eval time (tempo) --------------------------------------------
  //
  // let last
  let beatIndex = -1
  let eventIndex = 0
  let currentTime = 0
  for await (const event of events) {
    //
    // Set the index (after the sort)
    //
    event.index = eventIndex
    if (event.onBeat) {
      beatIndex++
    }
    event.beatIndex = beatIndex
    //
    // Eval the duration of the event which is the minimum duration of its
    // contexts.
    //
    // event.forEach((context) => {
    //   const contextDuration = context.duration
    //   const eventDuration = event.duration
    //   const isLower =
    //     !eventDuration ||
    //     contextDuration.measure.compare(eventDuration.measure) < 0
    //   if (isLower) {
    //     event.duration = clone(contextDuration)
    //   }
    // })
    //
    // Eval the time
    //
    const bps = event.tempo / 60
    const beatTime = 1000 / bps
    const next = events[eventIndex + 1]
    let beatDuration
    if (next) {
      const beat = event.position.beat.valueOf()
      const nextBeat = next.position.beat.valueOf()
      beatDuration = nextBeat - beat
    } else {
      // When it's the last event, the event duration is the duration of its
      // context. For the last event all of its context should have the same
      // duration
      beatDuration = event[0].duration.beat.valueOf()
    }
    const time = beatDuration * beatTime

    event.duration = { time: Math.round(time) }
    event.position.time = currentTime
    currentTime += time

    // const time = event.duration.beat.valueOf() * beatDuration
    // event.duration.time = Math.round(time)
    // event.position.time = 0
    // if (last) {
    //   event.position.time = last.position.time + last.duration.time
    // }
    //
    // When it's the last event, we update the total time
    //
    if (!next) {
      report.duration.time = event.position.time + event.duration.time
    }
    // last = event
    eventIndex++
    if (eventIndex % 50 === 0) {
      await nextTick('SHEET BUILDER STEP 3.' + eventIndex, tickDate)
    }
  }

  report.events = () => events

  // events.forEach((event)=>{
  //   event.context.forEach(context=>{
  //     context.duration
  //   })
  // })

  return { events, fragments, beats }
}

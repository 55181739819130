export default class SheetUpdates extends Array {
  has(...args) {
    return args.some((arg) => this.indexOf(arg) > -1)
  }
  add(...args) {
    args.forEach((arg) => {
      if (this.indexOf(arg) === -1) this.push(arg)
    })
  }
  remove(...args) {
    args.forEach((arg) => {
      const index = this.indexOf(arg)
      if (index !== -1) {
        this.splice(index, 1)
      }
    })
  }
  clear() {
    this.length = 0
  }
}

import Sprite from './data/Sprite'
import Head from './Head'

export default class KeySignature {
  constructor(track) {
    const tune = track.measure.tune
    this.track = track
    this.tune = tune
    this.keys = tune.pitches.map(() => {
      var key = new Sprite()
      key.svg = `#alt-${tune.altName}`
      key.png = `alt-${tune.altName}`
      return key
    })
  }
  evalLayout(styles) {
    const altWidth = styles.alt[this.tune.altName].width
    const altCount = this.tune.pitches.length
    this.measuredWidth = (altCount - 1) * styles.keySignature.offsetX + altWidth
  }

  layoutCrossAxis(styles) {
    this.keys.forEach((key, keyIndex) => {
      const pitch = this.tune.pitches[keyIndex]
      let octava
      if (this.tune.alt < 0) {
        octava = ['c', 'd', 'e'].indexOf(pitch) > -1 ? 6 : 5
      } else {
        octava = ['a'].indexOf(pitch) > -1 ? 5 : 6
      }
      const top = Head.lineIndexFromPitch(pitch, octava).absolute

      key.set('y', styles.staff.top - (top - 2) * styles.staff.unit)
    })
  }

  layoutMainAxis(posX, styles) {
    this.keys.forEach((key, keyIndex) => {
      const tuneX = styles.keySignature.offsetX * keyIndex
      key.set('x', tuneX + posX)
    })
    this.width = this.measuredWidth
  }

  render() {
    return {
      keys: this.keys.map((key, keyIndex) => ({
        id: `key-${this.track.code}-${keyIndex}`,
        x: key.x,
        y: key.y,
        svg: key.svg,
        png: key.png
      }))
    }
  }
}

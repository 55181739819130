import Bloc from './Bloc'
import Sprite from './data/Sprite'

export default class Label extends Bloc {
  constructor(track, event, context, raw) {
    super(track, event, context, raw)
    raw = raw.replace(/[()]/g, '')
    this.raw = raw
    if (!this.label) this.label = raw != '-' && raw != '·' ? raw : ''
    this.isEmpty = raw === '-' || raw === '·'
    this.isRest = raw === '*'
    this.text = new Sprite()
    if (!this.isEmpty) {
      this.bound = new Sprite()
    }
  }

  //
  // STAFF MEASURER ============================================================
  //

  evalLayout(currentCol, measurer, styles, last) {
    const raw = this.rawMeasure || this.label || this.raw
    //
    // Measure text
    //
    const bound = measurer(raw, this.type)
    const textWidth = Math.round(!this.isEmpty ? bound.width : 0)
    const textHeight = Math.round(bound.height)
    const halfTextWidth = Math.round(textWidth / 2)
    //
    // As the text is centered, half of its size should be reserved in the prev
    // bloc if it exists. Otherwise the size will be reserved in the before of
    // the track (see evalBefore())
    //
    if (last) {
      last.extendLayout(halfTextWidth - styles.lyric.offset)
    } else {
      this.track.extendLayout(halfTextWidth - styles.lyric.offset)
    }
    let measuredWidth = halfTextWidth + styles.lyric.offset
    this.text.set('width', textWidth)
    this.text.set('halfWidth', measuredWidth)
    this.text.set('height', textHeight)
    this.set('measuredWidth', measuredWidth)
    this.set('measuredHeight', textHeight)
    if (this.bound) {
      this.bound.set('y', -styles.bound.padding)
      this.bound.set('width', textWidth + styles.bound.padding * 2)
      this.bound.set('height', textHeight + styles.bound.padding * 2)
    }
    super.evalLayout(currentCol)
  }

  evalBefore(styles) {
    const halfTextWidth = Math.floor(this.text.width / 2)
    return halfTextWidth - styles.lyric.offset
  }

  //
  // STAFF COMPOSER ============================================================
  //

  layoutMainAxis(posX, styles) {
    let textX = Math.ceil(this.text.width / 2)
    textX = styles.lyric.offset - textX
    this.text.set('x', textX)
    if (this.bound) {
      this.bound.set('x', textX - styles.bound.padding)
    }
    super.layoutMainAxis(posX)
  }

  //
  // RENDERER ==================================================================
  //

  render(r) {
    if (!r) r = {}
    r.id = this.id
    if (this.label) {
      r.label = {
        id: `${this.id}-label`,
        value: this.label,
        x: this.text.x,
        y: this.text.y || 0,
        style: this.type
      }
    }
    if (this.bound) {
      r.bound = {
        x: this.bound.x,
        y: this.bound.y,
        width: this.bound.width,
        height: this.bound.height
      }
    }
    return super.render(r)
  }
}

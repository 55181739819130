/**
 * Created in : 'sheet-builder'
 * Ancestors : 'Event'
 */
export default class Group extends Array {
  constructor() {
    super()
    this.depth = NaN
    this.parent = null
  }
}

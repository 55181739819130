import Sprite from './data/Sprite'
import Label from './Label'

export default class Part extends Label {
  constructor(track, event, context, raw) {
    super(track, event, context, raw.toUpperCase())
    this.type = 'part'
    this.isPart = true
    this.id = `part-${this.code}`
    this.background = new Sprite()
  }

  //
  // STAFF BUILDER =============================================================
  //

  shouldMerge() {
    const current = this
    const last = this.track.blocs[this.track.blocs.length - 1]
    //
    // Merge only empty bloc and if bloc is not the first of the measure
    //
    if (current.raw === '-' && last) {
      return { to: last }
    }
  }

  //
  // STAFF MEASURER ============================================================
  //

  evalLayout(currentCol, measurer, styles, last) {
    const fakeMeasurer = (a, b) => {
      const measure = measurer(a, b)
      measure.width += styles.part.paddingHorizontal * 2
      return measure
    }
    super.evalLayout(currentCol, fakeMeasurer, styles, last)
    if (!last) {
      this.track.extendLayout(this.track.extendedBefore)
    }
    this.background.set('height', styles.part.thickness)
  }

  evalBefore(styles) {
    return super.evalBefore(styles)
  }

  //
  // STAFF COMPOSER ============================================================
  //

  layoutCrossAxisBeforeTrack(styles, trackIsEmpty) {
    const style = styles.part
    const paddingTop = trackIsEmpty ? style.emptyPaddingTop : style.paddingTop
    const paddingBottom = trackIsEmpty
      ? style.emptyPaddingBottom
      : style.paddingBottom

    this.text.set('y', paddingTop + styles.part.offsetText)
    if (this.bound) {
      this.bound.increase('y', paddingTop)
    }
    const thickness = styles.part.thickness
    const backgroundY = (this.text.height - thickness) / 2 + paddingTop
    this.background.set('y', backgroundY, true)
    this.background.color = trackIsEmpty
      ? styles.part.emptyColor
      : styles.part.color

    return this.measuredHeight + paddingTop + paddingBottom
  }

  layoutMainAxis(posX, styles) {
    super.layoutMainAxis(posX, styles)
    let backgroundX = 0
    if (this.label) {
      backgroundX += this.text.halfWidth
    }
    this.background.set('x', backgroundX)
    this.text.increase('x', styles.part.paddingHorizontal)
    const backgroundWidth = this.width - backgroundX - this.extendedWidth
    this.background.set('width', Math.max(0, backgroundWidth))
  }

  //
  // RENDERER ==================================================================
  //

  render() {
    const r = {}
    r.id = this.id
    r.background = {
      x: this.background.x,
      y: this.background.y,
      width: this.background.width,
      height: this.background.height,
      color: this.background.color
    }
    return super.render(r)
  }
}

import Sprite from './data/Sprite'

export default class Tie extends Sprite {
  constructor(from, to, track) {
    super()
    this.from = from
    this.to = to
    this.isSplitted = false
    this.isOnMultipleMeasures = from.track != to.track
    this.track = track
    const index = track.ties?.length || 0
    this.id = `tie-${track.code}-${index}`
  }

  //
  // STAFF MEASURER ============================================================
  //

  layoutCrossAxis(styles) {
    let isDown
    if (this.from.stem) {
      isDown = this.from.stem.isUp
    } else {
      isDown = this.from.head.y > styles.staff.middle
    }
    let y = this.from.head.y
    if (isDown) {
      y += this.from.head.height
    }
    this.isDown = isDown
    this.set('y', y)

    let top = isDown ? y + styles.tie.height : y
    this.from.track.updateOverflow(top, styles.tie.height)
  }

  //
  // STAFF COMPOSER ============================================================
  //

  split() {
    const clone = new Tie(this.to, 'right', this)
    clone.splitFrom = this
    clone.isDown = this.isDown
    clone.set('y', this.y)
    this.to.track.addTie(clone)
    this.to = 'left'
    this.isSplitted = true
    this.splitTo = clone
  }

  merge() {
    const clone = this.splitTo
    clone.from.track.removeTie(clone)
    this.to = clone.from
    this.isSplitted = false
    delete this.splitTo
  }

  layoutMainAxis(posX, styles) {
    let w
    let x = this.from.head.x + this.from.trackX
    if (this.to === 'left') {
      w = -styles.tie.before
    } else if (this.to === 'right') {
      w = this.from.width
      x += this.from.head.width
    } else {
      const fromX = this.from.head.x + this.from.rowX
      const toX = this.to.head.x + this.to.head.width + this.to.rowX
      w = toX - fromX
    }
    const direction = this.isDown ? 1 : -1
    const h = styles.tie.height * direction
    const handleX = w * styles.tie.handle
    const t = styles.tie.thickness
    let path = `M 0 0 `
    path += `C 0 0 ${handleX} ${h} ${w / 2} ${h}`
    path += `C ${w - handleX} ${h} ${w} 0 ${w} 0 `
    path += `S ${w - handleX} ${h + t} ${w / 2} ${h + t} `
    path += `C ${handleX} ${h + t} 0 0 0 0`
    this.path = path
    this.set('x', x)
    this.set('pixiX', w < 0 ? x + w : x)
    this.set('width', Math.abs(w))
    this.set('scale', direction)
  }

  render() {
    let pngIndex = Math.floor(Math.log(this.width / 15) / Math.log(2)) + 1
    pngIndex = Math.min(4, Math.max(0, pngIndex))
    return {
      id: this.id,
      png: `tie-${pngIndex}`,
      path: this.path,
      transform: this.translate,
      x: this.pixiX,
      y: this.y,
      width: this.width,
      scale: this.scale
    }
  }
}

import Sprite from './data/Sprite'

export default class Staff extends Sprite {
  constructor(track) {
    super()
    this.track = track
  }
  layoutCrossAxis(styles) {
    this.set('y', 0)
    this.set('thickness', styles.staff.line.height)
    this.set('gutter', styles.staff.unit)
    this.set('lineCount', styles.staff.line.count)
  }
  layoutMainAxis(width) {
    this.set('width', width)
  }
  render() {
    return {
      id: `staff-${this.track.code}`,
      lineCount: this.lineCount,
      translate: this.translate,
      thickness: this.thickness,
      width: this.width,
      height:
        this.gutter * this.lineCount - 1 + this.lineCount * this.thickness,
      gutter: this.gutter
    }
  }
}

<template>
  <div :class="sheetClass">
    <!-- LYRIC -->
    <component
      class="renderer"
      :is="renderer"
      v-on="$listeners"
      ref="renderer"
      :head="head"
      :selection="selection"
      :force-canvas="forceCanvas"
      :verbose="verbose"
      :locked="locked"
      :compact="compact"
      @refresh="onRefresh()"
    >
      <template v-slot:header><slot name="header"></slot></template>
      <template v-slot:footer> <slot name="footer"></slot> </template>
      <template v-slot:empty> <slot name="empty"></slot> </template>
    </component>
  </div>
</template>

<script>
import { parse as sheetParser } from '../../../../jelly-flux/src/'

import sheetBuilder from '../../processors/sheet-builder'
import { until, applyStyle, timeout } from '../../utils/tools-utils'
import SheetSettings from '../../settings/SheetSettings.js'
import SheetUpdates from '../../settings/SheetUpdates.js'
import Report from '../../models/Report'

import debounce from 'lodash/debounce'

export default {
  name: 'Sheet',
  components: {
    lyric: () => import('../renderer/LyricRenderer.vue'),
    chart: () => import('../renderer/ChartRenderer.vue'),
    staff: () => import('../renderer/StaffRenderer.vue')
  },
  props: {
    source: String,
    options: Array,
    forceCanvas: Boolean,
    head: Number,
    selection: Object,
    report: Object,
    rendering: Boolean,
    verbose: Boolean,
    locked: Boolean,
    compact: Boolean,
    settings: Object,
    lockSize: Boolean
  },
  data: () => ({
    availableWidth: 0,
    pendingUpdate: null,
    renderer: null
    // sheetClass: null
  }),

  computed: {
    sheetClass() {
      const cls = ['sheet']
      if (this.compact) cls.push('compact')
      return cls
    }
  },

  // ===========================================================================

  watch: {
    source() {
      const updates = this._updates
      updates.add('source')
      this.update()
    },
    options() {
      if (!this.source) return
      this.update()
    }
  },

  // ===========================================================================

  mounted() {
    window.addEventListener('resize', this.updateAvailableWidth)
    this.updateAvailableWidth()
    //
    // Create sheet update
    //
    this._updates = new SheetUpdates()
    this._settings = this.settings || new SheetSettings()
    const cache = {}
    this.getCache = () => cache
    //
    // Create debounced update function
    //
    this.update = debounce(this.debouncedUpdate, 200)
    //
    // Fist build if there is a source
    //
    if (this.source) {
      this._updates.add('source')
      this.update()
    }
  },

  // ===========================================================================

  destroyed() {
    window.removeEventListener('resize', this.updateAvailableWidth)
    //
    // Abort pending update (if any)
    //
    if (this.pendingUpdate) {
      window.cancelAnimationFrame(this.pendingUpdate)
      this.pendingUpdate = null
    }
    //
    // Clear dynamics proprties
    //
    this.$emit('update:rendering', false)
    this.$emit('update:report', null)
    //
    // Clear data
    //
    this._evts = null
    this._flows = null
    this._fragments = null
    this._updates = null
    this._settings = null
    this._report = null
    this.availableWidth = 0
    this.renderer = null
  },

  // ===========================================================================

  methods: {
    forceUpdate(...args) {
      args.forEach((a) => this._updates.add(a))
      this.update()
    },

    updateSelection() {
      const renderEl = this.$refs.renderer
      renderEl?.updateSelection()
    },

    debouncedUpdate() {
      let bound
      if (this.lockSize && this._lastWidth) {
        bound = this._lastWidth
      } else {
        bound = this.$el && this.$el.getBoundingClientRect()
      }
      //
      // If there is a pending update, we just wait for it. That will do in the
      // next animation frame
      //
      if (this.pendingUpdate) return
      //
      // If an update is running, we schedule another update at the next
      // available frame (and so on until the current update is completed)
      //
      if (this.rendering || !bound || !bound.width) {
        const that = this
        this.pendingUpdate = window.requestAnimationFrame(() => {
          that.pendingUpdate = null
          that.debouncedUpdate()
        })
      } else {
        //
        // There is no update running, and the build request is debounced. We
        // can go now !
        //
        this.render(isNaN(bound.width) ? bound : bound.width)
        this._lastWidth = bound
      }
    },

    // =========================================================================

    createDynamicStyleSheet(settings) {
      const headElt = document.getElementsByTagName('head')[0]
      if (!headElt._hasCustomSheetStyle) {
        this._updates.add('style')
        let style = document.createElement('style')
        headElt.appendChild(style)
        applyStyle(style.sheet, 'sheet', settings.sheet.css)
        applyStyle(style.sheet, 'lyric-renderer', settings.lyric.css)
        applyStyle(style.sheet, 'chart-renderer', settings.chart.css)
        headElt._hasCustomSheetStyle = true
      }
    },

    // =========================================================================

    async render(availableWidth) {
      //
      // Compile options to the settings
      //
      const updates = this._updates
      const settings = this._settings
      const options = this.options || []
      options.forEach((update) => {
        if (settings.set(update.path, update.value)) {
          updates.add(update.name)
        }
      })
      //
      // Create dynamic css styles
      //
      this.createDynamicStyleSheet(settings)
      //
      // Build aborted if there is no update required
      //
      if (!updates.length) return
      //
      // Start of the update.
      // Note :  Depending of the updates required, some steps can be bypassed.
      // For that we need to access to values of a previous render.
      //
      const report = new Report(updates.has('source') ? null : this._report)
      this._report = report
      this.startBuild(report, settings, options, updates)
      await timeout(10)
      try {
        const source = this.source
        const chars = settings.chars
        const manifest = settings.manifest
        let flows = this._flows
        let events = this._evts
        let fragments = this._fragments
        //
        // STEP 1 : Parsing data (if source is string)
        //
        if (updates.has('source', 'chars', 'manifest')) {
          if (source) {
            const parser = await sheetParser(source, chars, manifest, report)
            flows = this._flows = parser.flows
            updates.add('flows')
            this.progressBuild('SHEET PARSER', { flows }, report)
          }
        }
        //
        // STEP 2 : Build sheet
        //
        if (updates.has('flows')) {
          const builder = await sheetBuilder({
            flows,
            chars,
            manifest,
            report
          })
          events = this._evts = builder.events
          fragments = this._fragments = builder.fragments
          updates.add('events', 'fragments')
          this.progressBuild('SHEET BUILDER', { events, fragments }, report)
        }
        //
        // STEP 3 : Update the renderer
        //
        if (!this.renderer || updates.has('renderer')) {
          this.renderer = settings.user.renderer
          await until(() => this.$refs.renderer)
        }
        //
        // STEP 4 : Render
        //
        const renderEl = this.$refs.renderer
        //
        // TODO : Add margin from settings
        //
        const ratio = this.compact ? 0.5 : 1
        const padding = Math.round(settings.sheet.padding * ratio)
        const margin = Math.round(settings.sheet.margin * ratio)
        const scrollWidth = 12
        const canvasExtra = 1
        availableWidth -= (margin + padding) * 2 + scrollWidth + canvasExtra
        await renderEl.render({
          availableWidth,
          updates,
          settings,
          flows,
          events,
          fragments,
          report
        })
        this.progressBuild('SHEET RENDERER', null, report)
        this.endBuild(report)
      } catch (e) {
        //
        // We should not have error unless there's a bug. In this case we don't
        // want a crash, juste a message in the console.
        //
        await timeout(50)
        this.endBuild(report)
        console.error(e)
      }
    },

    startBuild(report, settings, options, updates) {
      report.steps = []
      const now = Date.now()
      this._renderTimestamp = { start: now, current: now }
      this.$emit('update:rendering', true)
      this._rendering = true
      if (this.verbose) {
        console.groupCollapsed('RENDERING')
        console.log('SETTINGS', { settings, options, updates })
      }
    },

    progressBuild(step, log, report) {
      const timestamp = this._renderTimestamp
      const now = Date.now()
      const stepDuration = Math.round((now - timestamp.current) / 10) / 100
      timestamp.current = now
      report.steps.push({ name: step, time: stepDuration })
    },

    endBuild(report) {
      const timestamp = this._renderTimestamp
      const gap = Date.now() - timestamp.start
      const time = Math.round(gap / 10) / 100
      report.renderTime = time
      this._updates.clear()
      if (this.verbose) {
        console.log('REPORT', report.log())
        console.groupEnd()
      } else {
        console.log(`RENDERING ${time}s`)
      }
      this.$emit('update:rendering', false)
      this._rendering = false
      this.$emit('update:report', report)
    },

    updateAvailableWidth() {
      if (this.update) {
        this._updates.add('width')
        this.update()
      }
    },

    onRefresh() {
      //
      // A refresh is asked by the renderer
      //
      this._updates.add('source')
      this.update()
    }
  }
}
</script>

<style scoped>
.renderer {
  justify-content: center;
  text-align: center;
}
.renderer /deep/ .paper {
  min-width: var(--sheet-min-width);
  min-height: var(--sheet-min-height);
  margin: var(--sheet-margin);
  padding: var(--sheet-padding);
  background-color: var(--sheet-background-color);
  box-shadow: var(--sheet-box-shadow);
  display: inline-block;
}
.compact .renderer /deep/ .paper {
  margin: calc(var(--sheet-margin) / 2);
  padding: calc(var(--sheet-padding) / 2);
}
.renderer /deep/ .empty-slot {
  min-height: var(--sheet-min-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.staff-renderer {
  text-align: center;
}
</style>

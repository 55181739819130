export const splitOut = (str, splitChar, startChar, endChar, strChar) => {
  const result = []
  const charCount = str.length
  let pool = ''
  let depth = 0
  let inString = false
  for (let charIndex = 0; charIndex < charCount; charIndex++) {
    const char = str[charIndex]

    if (char === strChar && depth === 0) {
      inString = !inString
      // pool += char
    } else if (inString) {
      pool += char
    }

    if (!inString) {
      if ((char != splitChar && char != strChar) || depth > 0) {
        pool += char
        if (char == startChar && (startChar != endChar || depth == 0)) {
          depth++
        } else if (char == endChar && depth > 0) {
          depth--
        }
      }
    }

    if (
      ((!inString && char == splitChar) || charIndex == charCount - 1) &&
      pool.length &&
      depth == 0
    ) {
      result.push(pool)
      pool = ''
    }
  }

  if (pool.length /*|| depth > 0*/) {
    // throwParseError('unclosed-division')
    result.push(pool)
  }
  return result
}

// =============================================================================
// =============================================================================
// =============================================================================

const regReserved = '\\^$.!|?*+()[]{}-'.split('')
export const charForRegex = (chars) =>
  chars.map((char) => (regReserved.indexOf(char) > -1 ? '\\' + char : char))

// =============================================================================
// =============================================================================
// =============================================================================

export const trimChar = (string, chars) => {
  while (chars.indexOf(string.charAt(0)) > -1) {
    string = string.substring(1)
  }

  while (chars.indexOf(string.charAt(string.length - 1)) > -1) {
    string = string.substring(0, string.length - 1)
  }

  return string
}

// =============================================================================
// =============================================================================
// =============================================================================

export const getCharPos = (string, float) => {
  return Array.from(string).map((char, pos) => ({
    char,
    pos,
    float: float || false
  }))
}

// =============================================================================
// =============================================================================
// =============================================================================

export const nextTick = (name, date) => {
  return new Promise((resolve) => {
    const now = Date.now()
    const gap = now - date.getTime()
    if (gap > 100) {
      console.warn(`Tick interval too long on '${name}' :  ${gap}ms`)
    }
    if (gap > 50) {
      window.requestAnimationFrame(function () {
        resolve()
        date.setTime(now)
      })
    } else {
      resolve()
    }
  })
}

import { Paragraph, Line, Flow, Slot, Division, Warning } from './class'
import { splitOut, trimChar, charForRegex, nextTick, getCharPos } from './utils'

import cloneDeep from 'lodash/cloneDeep'

export const parse = async (rawData, chars, manifest, report) => {
  const tickDate = new Date()
  //
  //
  // Chars
  //
  const SPACER = chars.spacer
  const DELIMITER = chars.delimiter
  const OPENFLOAT = chars.floating
  const OPENDIV = chars.division.open
  const CLOSEDIV = chars.division.close
  const CONTEXT = chars.context
  const EMPTY = chars.empty
  const STRING = chars.string
  const EXTENDS = chars.extends
  const BREAKLINE = chars.breakline
  //
  // Context
  //
  const allSymbols = manifest.allSymbols
  const allSymbolsForReg = charForRegex(allSymbols)

  const suffixMatch = `^[${allSymbolsForReg.join('')}]$`
  const divChars = charForRegex([OPENDIV, CLOSEDIV])
  const divReg = `^([^${divChars[0]}]+)?${divChars[0]}(.+)?${divChars[1]}$`
  //
  // Empty lines
  //
  const emptyChars = [DELIMITER, OPENDIV, CLOSEDIV]
  const emptyDivChars = charForRegex([...emptyChars, SPACER, EMPTY])
  //
  // Floating lines
  //
  const floatChars = charForRegex([OPENFLOAT, SPACER])

  const floatReg =
    `^([${allSymbolsForReg.join('')}]${floatChars[1]}+)` +
    `${floatChars[0]}${floatChars[1]}+(.+)$`
  let floatPool = []
  const rawPosFilter = [SPACER, OPENDIV, CLOSEDIV, DELIMITER]
  //
  // Database
  //
  const lines = []
  const paragraphs = []
  const flows = []
  const warnings = report.warnings
  Warning.setPool(warnings)
  //
  // Counter, pointers
  //
  // Pointer  l'index du flow en cours.
  let currentFlowIndex = -1
  // Counter for each context
  let lineInFlowCount = 0
  let currentParagraph = null
  let colSizes = []

  const main = async () => {
    //
    //  STEP 1 : Parse raw data ================================================
    //
    await parseRaw()
    //
    // STEP 2 : Build divisions ================================================
    //
    await buildDivisions()
    //
    // STEP 3 : Build divisions ================================================
    //
    await measureDivisions()
    await nextTick('SHEET PARSER STEP 3', tickDate)
  }
  //
  // ===========================================================================
  //
  const parseRaw = async () => {
    const rawLines = rawData.split('\n')

    let rawLineIndex = 0
    for await (const raw of rawLines) {
      const rawLine = raw.trimEnd()
      //
      // When there is a empty line but with a current paragraph
      // 1: close the paragraph
      // 2: reset the flow index
      // 3: fill missing part of the flow
      //
      if (!rawLine) {
        if (currentParagraph) {
          currentParagraph = null
          currentFlowIndex = -1
          fillFlows(flows)
        }
        rawLineIndex++
        continue
      }
      //
      // Ignore comments
      //
      if (rawLine.startsWith('//')) {
        rawLineIndex++
        continue
      }

      const floatMatch = rawLine.match(floatReg)
      let charPos
      if (floatMatch) {
        //
        // Floating lines are stored into a pool which will be parsed at the
        // next non floating line
        //
        const floatContext = floatMatch[1]
        let floatContent = floatMatch[2]
        let floatRawLine = floatContext + CONTEXT + floatContent
        charPos = getCharPos(floatRawLine, true)
        //
        // Add quote to the content is (required)
        //
        const contentHasEmpty = floatContent.indexOf(SPACER) > -1
        const contentIsQuoted = floatContent.match(`${STRING}.+${STRING}`)
        if (contentHasEmpty && !contentIsQuoted) {
          floatContent = `${STRING}${floatContent}${STRING}`
          floatRawLine = floatContext + CONTEXT + floatContent
          charPos.splice(floatContext.length + 1, 0, {
            char: '`',
            pos: floatContext.length,
            float: true
          })
          charPos.push({
            char: '`',
            pos: floatRawLine.length,
            float: true
          })
        }
        floatPool.push([floatRawLine, rawLineIndex, charPos, true])
      } else {
        let charPos = getCharPos(rawLine)
        //
        // Pool floating line is parsed first
        //
        let newFloatPool = []
        let oldFloatPool = cloneDeep(floatPool)
        for await (const floatArgs of floatPool) {
          const floatLine = await parseRawLine(...floatArgs)
          newFloatPool.push(floatLine)
        }
        floatPool = newFloatPool
        //
        // The current line is then parsed
        //
        const line = await parseRawLine(rawLine, rawLineIndex, charPos)
        //
        // The floating lines content is fill with the one of the current line
        //
        if (line) {
          floatPool.forEach((floatLine) => {
            //
            // Use the empty content of the  line as template
            //
            floatLine.emptyContent = line.emptyContent
            let contentIsFilled = false
            const fillContent = floatLine.content
            let newContent = ''
            Array.from(line.emptyContent).forEach((content) => {
              if (content === EMPTY && !contentIsFilled) {
                newContent += fillContent
                contentIsFilled = true
              } else if (contentIsFilled || content === OPENDIV) {
                newContent += content
                const lastCharPos =
                  floatLine.charPos[floatLine.charPos.length - 1]
                floatLine.charPos.push({
                  char: '-',
                  pos: lastCharPos.pos,
                  float: true
                })
              }
            })
            if (newContent.length != floatLine.charPos.length) {
              throw new Error(
                'WTF : floatline content and charPos must have the same size'
              )
            }
            floatLine.content = newContent
          })
          //
          // Clear the pool
          //
          floatPool.length = 0
        } else {
          //
          // If there is no line, this is because a warning has been throw
          // We put the the floatPool at his initial position
          //
          floatPool = oldFloatPool
        }
      }
      rawLineIndex++
    }
    //
    // Check if there is floating line left
    //
    while (floatPool.length) {
      //
      // There are floating lines without content. There are ignored.
      //
      Warning.throw({
        type: 'floating-without-content',
        line: floatPool[0][1],
        char: 0,
        length: Infinity,
        raw: floatPool[0][1]
      })
      floatPool.shift()
    }
    //
    // A last fill is required at the end of the parsing
    //
    fillFlows(flows)
  }
  //
  // ===========================================================================
  //
  const parseRawLine = async (rawLine, rawLineIndex, charPos, floating) => {
    //
    // Get the content and the context of the line
    //
    let rawContext, rawContent, emptyContent
    const split = rawLine.split(CONTEXT)
    rawContext = split[0]
    charPos.splice(0, rawContext.length + 1)
    rawContent = split.slice(1).join(CONTEXT)
    const rawContextLength = rawContext.length
    rawContext = rawContext.trim()
    if (!rawContext || !rawContext.match(suffixMatch)) {
      //
      // UNKNOWN CONTEXT : Parsing aborted
      //
      Warning.throw({
        type: 'unknown-context',
        line: rawLineIndex,
        char: 0,
        length: (rawContext && rawContext.length) || 1,
        context: rawContext
      })
      return
    }
    const context = manifest.symbolIndex.get(rawContext)
    if (!rawContent) {
      //
      // UNKNOWN CONTENT : Parsing aborted
      //
      Warning.throw({
        type: 'unknown-content',
        line: rawLineIndex,
        char: rawContextLength,
        length: Infinity
      })
      return
    }
    //
    // Remove duplicated space char. This code equiv .trim() and
    // .replace(/\s+ /g, ' '), but we need to update charPos, so the operation
    // is done 'manually'
    //
    const contentLength = rawContent.length
    let rawChars = []
    let spaceBefore = false
    let firstChar = false
    let char
    for (var i = 0; i < contentLength; i++) {
      char = rawContent[i]
      const isSpacer = char == SPACER
      firstChar = firstChar || !isSpacer
      if ((isSpacer && spaceBefore) || !firstChar) {
        const spliceIndex = charPos.length - contentLength + i
        charPos.splice(spliceIndex, 1)
        continue
      }
      spaceBefore = isSpacer
      rawChars.push(char)
    }
    if (char === SPACER) {
      rawChars.pop()
      charPos.pop()
    }
    //
    // Evaluation empty content
    //
    const emptyReplace = `${charForRegex([EMPTY])[0]}+`
    emptyContent = rawChars
      .map((char, charIndex) => {
        if (char === SPACER) {
          return SPACER
        } else if (isNaN(char)) {
          return emptyChars.indexOf(char) > -1 ? char : EMPTY
        } else {
          let nextIndex = charIndex + 1
          while (nextIndex < rawChars.length) {
            const nextChar = rawChars[nextIndex++]
            if (nextChar === OPENDIV) return char
            if (isNaN(nextChar)) return EMPTY
          }
        }
      })
      .join('')
      .replace(new RegExp(emptyReplace, 'g'), EMPTY)
      .replace(/\[\]/g, EMPTY)
    //
    // Create the line
    //
    const line = new Line()
    line.content = rawChars.join('')
    line.context = context
    line.charPos = charPos.slice()
    line.indexInLines = lines.length
    line.indexInRaw = rawLineIndex
    line.emptyContent = emptyContent
    line.floating = Boolean(floating)
    lines.push(line)
    //
    // Get the current flow
    //
    let currentFlow
    while (currentFlowIndex < flows.length) {
      currentFlowIndex++
      currentFlow = flows[currentFlowIndex]
      if (currentFlow && currentFlow.context.symbol === rawContext) {
        break
      }
    }
    //
    // Create a flow (if none)
    //
    if (!currentFlow) {
      currentFlow = new Flow()
      currentFlow.lines = []
      currentFlow.context = context
      currentFlow.indexInFlows = flows.length
      flows.push(currentFlow)
    }
    //
    // (Pre)fill the missing lines (before adding) for line with a context which
    // has not be added in previous paragraph
    //
    fillFlows([currentFlow], !!currentParagraph, line)
    currentFlow.lines.push(line)
    lineInFlowCount = currentFlow.lines.length
    //
    // Create a new paragraph (if none)
    //
    if (!currentParagraph) {
      currentParagraph = new Paragraph()
      paragraphs.push(currentParagraph)
    }
    currentParagraph.push(line)
    await nextTick('SHEET PARSER PARSE LINE', tickDate)
    return line
  }
  //
  // ===========================================================================
  //
  const fillFlows = (flowList, prefill, newLine) => {
    const offset = prefill ? -1 : 0
    flowList.forEach((flow) => {
      const lastLine = flow.lines[flow.lines.length - 1]
      const refLine = newLine || lastLine
      const refCharPos = newLine
        ? newLine.charPos[0]
        : lastLine.charPos[lastLine.charPos.length - 1]
      let missingLine = Math.max(
        0,
        lineInFlowCount - flow.lines.length + offset
      )
      if (!missingLine) return
      //
      // Create a map of all flows index where to search empty values. First
      // indexes will be those of the previous flow (in reverse order), and then
      // those of the flow after the current flow.
      //
      // If the current flow is 3rd of 6 flows (indexInFlow = 2) the refsIndex
      // will be [1,0,3,4,5]
      //
      const refsIndex = Array(flows.length - 1)
        .fill()
        .map((a, i) => {
          return i < flow.indexInFlows ? flow.indexInFlows - 1 - i : i + 1
        })
      //
      // Creation of the missing lines
      //
      while (missingLine) {
        const autoLine = new Line()
        autoLine.auto = true
        //
        // Search for empty values
        //
        refsIndex.some((refIndex) => {
          const ref = flows[refIndex]
          let refEmptyLine = ref.lines[flow.lines.length]
          const emptyContent = refEmptyLine && refEmptyLine.emptyContent
          if (emptyContent) {
            autoLine.content = autoLine.emptyContent = emptyContent
            autoLine.indexInRaw = refLine.indexInRaw
            autoLine.charPos = []
            Array.from(autoLine.content).forEach((char) => {
              autoLine.charPos.push({
                char,
                pos: refCharPos.pos,
                float: refCharPos.float
              })
            })
            // const autoLineLength = Array.from(autoLine.content).reduce((a,v)=>{
            //   return a + (v!=)
            // })
            if (autoLine.content.length != autoLine.charPos.length) {
              throw new Error(
                'WTF : autoline content and charPos must have the same size'
              )
            }
            return true
          }
        })
        flow.lines.push(autoLine)
        missingLine--
      }
    })
  }
  //
  // ===========================================================================
  //
  const buildDivisions = async () => {
    // Flows are now completed with missing part and floating lines are merged
    // Parsing of each division can start
    //
    // let flowIndex = 0
    for await (const flow of flows) {
      //
      // The content of each line of the flow are merged
      //
      const breakline = flow.context.breakline
      const flowPositions = []
      const flowRaw = flow.lines.reduce((a, line) => {
        let lineContent = line.content
        let linePos = line.charPos
        //
        // Add a spacer if required
        //
        if (a && a[a.length - 1] != SPACER) {
          lineContent = SPACER + lineContent
          linePos.unshift({
            line: line.indexInRaw,
            char: SPACER,
            pos: linePos[0].pos
          })
        }
        //
        // We add break line to the content for each line (if the context
        // required it)
        //
        if (breakline) {
          const lineContentLength = lineContent.length
          for (var i = lineContentLength - 1; i > 0; i--) {
            const lineChar = lineContent.charAt(i)
            if (lineChar != SPACER && lineChar != DELIMITER) {
              const firstPart = lineContent.slice(0, i + 1)
              const lastPart = lineContent.slice(i + 1)
              const insertIndex = firstPart.search(/(.)\]+$/)
              let breakPos
              if (insertIndex > -1) {
                breakPos = insertIndex
                const firstPartA = firstPart.slice(0, breakPos + 1)
                const trimedPartA = firstPartA.trimEnd()
                const trimCount = firstPartA.length - trimedPartA.length
                linePos.splice(breakPos, trimCount)
                const firstPartB = firstPart.slice(insertIndex + 1)
                lineContent = trimedPartA + BREAKLINE + firstPartB + lastPart
                breakPos++
              } else {
                breakPos = firstPart.length
                lineContent = firstPart + BREAKLINE + lastPart
              }
              linePos.splice(breakPos, 0, {
                char: BREAKLINE,
                pos: linePos[breakPos - 1].pos
              })
              break
            }
          }
        }
        //
        // Add char position
        //
        linePos.forEach((charPos) => {
          flowPositions.push(Object.assign({ line: line.indexInRaw }, charPos))
        })
        //
        // Update line
        //
        line.raw = lineContent
        return a + lineContent
      }, '')
      //
      // Creation of the tree of slot and division
      //
      flow.slots = []
      flow.divisions = []
      flowPositions.pointer = 0
      if (flowRaw.length != flowPositions.length) {
        throw new Error('WTF : raw and charPos must have the same size')
      }
      await nextTick('SHEET PARSER BUILD DIVISIONS', tickDate)
      await parseDivision(flowRaw, flow, flow.divisions, flowPositions, true)
      // flowIndex++
      //
      // PAUSE
      //
      // await cb(flowIndex)
    }
  }
  //
  // ===========================================================================
  //

  /**
   * This will parse the 'raw' data into a `Division` and `Slot`
   *
   * That division will be added to the `divisions` array given in parameters.
   * That will be the current `flow` for root division (`isRoot`) or a parent
   * division for subdivision.
   *
   * @param {String} raw
   * @param {Flow} flow
   * @param {Array} divisions
   * @param {Array} flowPositions
   * @param {Boolean} isRoot
   * @returns
   */

  const parseDivision = async (raw, flow, divisions, flowPositions, isRoot) => {
    //
    // Update flowPositions with removing all spacer, delimiters and open and
    // close division. Only one time (isRoot).
    //
    if (isRoot) {
      let intoString = false
      flowPositions = flowPositions.filter((pos, rawIndex) => {
        const char = raw[rawIndex]
        if (char === STRING) {
          intoString = !intoString
        }
        if (char === SPACER && intoString) {
          return true
        }
        return rawPosFilter.indexOf(char) === -1
      })
      flowPositions.pointer = 0
    }
    //
    // Extract the raw slots
    //
    raw = trimChar(raw, [SPACER, DELIMITER])
    let rawSlots = splitOut(raw, SPACER, OPENDIV, CLOSEDIV, STRING)
    //
    //
    // Convert 4 slots length to 2 subdivisions (EXPERIMENTAL)
    //
    if (!isRoot && rawSlots.length === 4) {
      rawSlots = [
        `[${rawSlots[0]} ${rawSlots[1]}]`,
        `[${rawSlots[2]} ${rawSlots[3]}]`
      ]
    }
    //
    // Empty content can cause empty divisions. For these a empty slot is
    // created instead a division
    //
    if (!isRoot && !raw.match(`[^${emptyDivChars}]`)) {
      flowPositions.splice(flowPositions.pointer, rawSlots.length - 1)
      return createSlot(EMPTY, flow, divisions, flowPositions)
    }
    //
    // Parse rawSlots
    //
    let currentDivision
    rawSlots.forEach((rawSlot) => {
      if (rawSlot === DELIMITER) {
        //
        // Delimiters are ignored, but a new division will be created
        //
        currentDivision = null
      } else {
        //
        // Create the division
        //
        if (!currentDivision) {
          currentDivision = new Division()
          currentDivision.flow = flow
          if (isRoot) {
            divisions.push(currentDivision)
          }
        }
        //
        // Parse raw
        //
        const divMatch = rawSlot.match(divReg)

        if (divMatch) {
          const rawShortcut = divMatch[1]
          const rawSubdiv = divMatch[2]
          const charPos = flowPositions[flowPositions.pointer]
          let divisionSize
          if (!rawSubdiv) {
            const shortcutLength = (rawShortcut && rawShortcut.length) || 0
            Warning.throw({
              type: 'empty-division',
              line: charPos.line,
              char: charPos.pos + shortcutLength,
              length: 2
            })
            createSlot(EMPTY, flow, currentDivision, charPos)
            flowPositions.pointer += shortcutLength
          } else {
            //
            // Check division size
            //
            if (rawShortcut) {
              divisionSize = Number(rawShortcut)
              if (isNaN(divisionSize) || divisionSize < 1) {
                divisionSize = 1

                Warning.throw({
                  type: 'wrong-division-shortcut',
                  line: charPos.line,
                  char: charPos.pos,
                  length: rawShortcut.length,
                  value: rawShortcut
                })
                rawSlot = rawSubdiv
                flowPositions.pointer++
                divisionSize = 1
              }
            }
            //
            // Parse sub division
            //
            parseDivision(rawSubdiv, flow, currentDivision, flowPositions)

            //
            // Extends shorcut
            //
            if (divisionSize > 1) {
              //
              // Check if the division is empty
              //
              let newRaw = EXTENDS
              if (currentDivision.length === 1) {
                const uniqueContent = currentDivision[0]
                if (uniqueContent.raw && uniqueContent.raw === EMPTY) {
                  newRaw = EMPTY
                }
              }
              const charPos = {}
              Object.assign(charPos, flowPositions[flowPositions.pointer])
              for (let i = 0; i < divisionSize - 1; i++) {
                flowPositions.splice(flowPositions.pointer + i, 0, charPos)
                createSlot(newRaw, flow, currentDivision, flowPositions)
              }
            }
          }
        } else {
          //
          // Current slot creation
          //
          createSlot(rawSlot, flow, currentDivision, flowPositions)
        }
      }
    })
    //
    // Add the result to divisions (only for non root division)
    //
    if (!isRoot) {
      if (currentDivision.length === 1) {
        divisions.push(currentDivision[0])
      } else {
        divisions.push(currentDivision)
      }
    }
    await nextTick('SHEET PARSER BUILD DIVISION', tickDate)
  }
  //
  // ===========================================================================
  //

  /**
   * Create a `Slot`.
   *
   * The `pos` parameter is the position or the `raw` (line and char). It can
   * given as an Array or as an Object. The last case is when `Slot` are
   * auto created when there are division length mismatch
   *
   * @param {String} rawSlot
   * @param {Flow} flow
   * @param {Division} division
   * @param {Array|Object} pos
   * @returns
   */

  const createSlot = (raw, flow, division, pos) => {
    const slot = new Slot()
    //
    // Raw
    //
    if (raw.endsWith(BREAKLINE)) {
      raw = raw.slice(0, -1)
      slot.breakline = true
    }
    //
    // RawPos
    //
    let rawPos
    let length = raw.length
    if (Array.isArray(pos)) {
      rawPos = pos[pos.pointer]
      pos.pointer += raw.length
      if (rawPos.char === STRING) {
        // pos.pointer += 1
        length += 2
      }
      if (slot.breakline) pos.pointer++
    } else {
      rawPos = pos
    }
    rawPos = Object.assign({ length }, rawPos)
    slot.rawPos = rawPos
    //
    // Check regex
    //
    const manifestItem = flow.context
    if (manifestItem.regex && raw != EMPTY && raw != EXTENDS) {
      const match = raw.match(manifestItem.regex)
      if (match) {
        slot.rawMatch = match
      } else {
        Warning.throw({
          type: 'content-match',
          line: rawPos.line,
          char: rawPos.pos,
          length: raw.length,
          raw: raw
        })
        raw = EMPTY
      }
    }
    slot.raw = raw
    slot.division = division
    slot.flow = flow
    flow.slots.push(slot)
    division.push(slot)
    return slot
  }
  //
  // ===========================================================================
  //
  const measureDivisions = async () => {
    const getLastSlot = (division) => {
      const last = division[division.length - 1]
      return last instanceof Division ? getLastSlot(last) : last
    }
    //
    // Eval each division length
    //
    const divisionLengths = []
    flows.forEach((flow) => {
      flow.divisions.forEach((division, divIndex) => {
        const divisionLength = divisionLengths[divIndex] || 0
        divisionLengths[divIndex] = Math.max(divisionLength, division.length)
      })
    })
    //
    // Ajust each flow
    //
    const divCount = divisionLengths.length
    const max = divCount > 1 ? 12 : Math.Infinity
    flows.forEach((flow) => {
      flow.divisions.forEach((division, divIndex) => {
        const divisionLength = Math.min(max, divisionLengths[divIndex])
        const currentLength = division.length
        const diff = divisionLength - currentLength
        if (diff > 0) {
          //
          // When the div is smaller than the biggest div (at the index)
          //
          const lastSlot = getLastSlot(division)
          for (let i = division.length; i < divisionLength; i++) {
            createSlot(EMPTY, flow, division, lastSlot.rawPos)
          }
          if (!lastSlot.rawPos.float) {
            //
            // We throw warning, for non floating rawpos
            //
            Warning.throw({
              type: 'metric-mismatch',
              line: lastSlot.rawPos.line,
              char: lastSlot.rawPos.pos,
              length: lastSlot.rawPos.length,
              max: divisionLength,
              current: currentLength,
              index: divIndex,
              context: flow.context
            })
          }
        } else if (diff < 0) {
          //
          //  When the div is bigger than the max authorized
          //
          const firstRemoved = division[divisionLength]
          division.splice(divisionLength, -diff)
          if (!firstRemoved.rawPos.float) {
            Warning.throw({
              type: 'metric-overflow',
              line: firstRemoved.rawPos.line,
              char: firstRemoved.rawPos.pos,
              length: firstRemoved.rawPos.length,
              count: currentLength
            })
          }
        }
      })
      //
      // When the flow doesn't have as much div than other
      //
      const missingCount = divCount - flow.divisions.length
      if (missingCount) {
        let lastRawPos
        if (flow.divisions.length) {
          const lastDiv = flow.divisions[flow.divisions.length - 1]
          const lastSlot = getLastSlot(lastDiv)
          lastRawPos = lastSlot.rawPos
        } else {
          lastRawPos = flow.lines[0].charPos[0]
        }
        for (let j = flow.divisions.length; j < divCount; j++) {
          const newDiv = new Division()
          const slotCount = divisionLengths[j]
          for (let i = 0; i < slotCount; i++) {
            createSlot(EMPTY, flow, newDiv, lastRawPos)
          }
          flow.divisions.push(newDiv)
        }
        Warning.throw({
          type: 'division-mismatch',
          line: lastRawPos.line,
          char: lastRawPos.pos,
          length: lastRawPos.length,
          context: flow.context.symbol,
          missing: missingCount
        })
      }
      //
      // Remove non useful data
      //
      delete flow.slots
      delete flow.lines
    })
  }
  //
  // ===========================================================================
  //
  const evalFlowRaw = () => {
    for (const flow of flows) {
      let colIndex = 0
      //
      // Recursive mapping of divisions. That will return an division but only
      // with raw data. OPENDIV and CLOSEDIV bound raw for subdivision.
      //
      const mapDivision = (division) => {
        return division.map((divItem) => {
          if (divItem instanceof Division) {
            const subDivision = divItem
            return OPENDIV + mapDivision(subDivision).join(SPACER) + CLOSEDIV
          } else {
            const slot = divItem
            return slot.raw
          }
        })
      }
      //
      // Gather raw data and measure size. Values are temporary stored into
      // flow.raw but it still an array. This will be reduced to string only
      // when all cols measurement are done.
      //
      flow.raw = flow.divisions.map((division) => {
        const values = mapDivision(division)
        //
        // Measure col size
        //
        values.forEach((value) => {
          colSizes[colIndex] = Math.max(value.length, colSizes[colIndex] || 0)
          colIndex++
        })
        return values
      })
    }
    //
    // Reducing each flow raw built in the previous step. Note that this part
    // is not necessary, but it's usefull to export the data to a string.
    // TODO : Move this part
    //
    const delimiter = SPACER + DELIMITER + SPACER
    for (const flow of flows) {
      let colIndex = 0
      flow.raw =
        flow.raw
          .map((rawDiv) =>
            rawDiv
              .map((rawItem) => rawItem.padEnd(colSizes[colIndex++], SPACER))
              .join(SPACER)
          )
          .join(delimiter) +
        SPACER +
        DELIMITER
    }
  }
  //
  // ===========================================================================
  //
  const toString = () => {
    if (!flows.length) return ''
    if (!flows[0].raw) {
      evalFlowRaw()
    }
    return flows
      .map((flow) => {
        return [flow.context.name, CONTEXT, flow.raw].join(SPACER)
      })
      .join('\n')
  }
  //
  // ENTRY POINT ===============================================================
  //
  // try {
  await main()
  // } catch (e) {
  //   Warning.throw({
  //     type: 'unknown-error',
  //     log: () => e
  //   })
  // }
  //
  // Compile data
  //
  // parser.lines = lines
  // parser.paragraphs = paragraphs
  // parser.flows = flows
  // parser.options = opts
  // parser.warnings = warnings
  // parser.toString = toString
  return { flows, paragraphs, lines, toString }
}

import Sprite from './data/Sprite'

export default class Bloc extends Sprite {
  constructor(track, event, context, raw) {
    super()
    const cname = context.name
    const isNameUpper = cname.charAt(0).toUpperCase() + cname.slice(1)

    this[`is${isNameUpper}`] = true
    this.code = `${track.code}-${track.blocs.length}`
    this.raw = raw
    this.rawPos = Object.assign({}, context.rawPos)
    this.tune = track.measure.tune
    this.breakline = track.measure.breakline
    //
    // Timestamp
    //
    this.start = {
      time: event.position.time
    }
    this.position = {
      measure: event.position.measure.clone(),
      beat: event.position.beat.clone()
    }
    this.duration = {
      measure: context.duration.measure.clone(),
      beat: context.duration.beat.clone(),
      metric: context.duration.metric.clone(),
      time: event.duration.time
    }
    //
    //
    // From Event
    //
    this.events = new Map([[event.index, event]])
    this.beatIndex = event.beatIndex
    this.eventIndex = event.index
    //
    // Display
    //
    this.class = ['bloc']
    this.content = new Sprite()
    //
    // Flags (will be updated later, because some bloc will be filtered)
    //
    this.isFirstOfMeasure = false
    this.isLastOfMeasure = false
    //
    // Ancestor
    //
    this.track = track
    track.blocs.push(this)

    this.extendedWidth = 0
  }

  get rowX() {
    return this.x + this.track.measure.x
  }

  get trackX() {
    return this.x
  }

  //
  // STAFF BUILDER =============================================================
  //

  merge(bloc) {
    const duration = this.duration
    const currentDuration = bloc.duration
    this.events = new Map([...this.events, ...bloc.events])
    const rawPosEnd = bloc.rawPos.pos + bloc.rawPos.length
    this.rawPos.length = rawPosEnd - this.rawPos.pos
    this.duration = {
      measure: duration.measure.add(currentDuration.measure),
      beat: duration.beat.add(currentDuration.beat),
      metric: duration.metric.add(currentDuration.metric),
      time: duration.time + currentDuration.time
    }
  }

  updateIndex(indexInTrack) {
    this.indexInTrack = indexInTrack
    this.isFirstOfMeasure = indexInTrack === 0
    this.isLastOfMeasure = indexInTrack === this.track.blocs.length - 1
    this.code = `${this.track.code}-${indexInTrack}`
  }

  addTuplet(tuplet) {
    if (!this.tuplets) {
      this.tuplets = []
    }
    this.tuplets.push(tuplet)
  }
  //
  // STAFF MEASURER ============================================================
  //

  evalLayout(currentCol) {
    const duration = this.duration.measure
    const colCount = this.track.measure.cols.length
    const colSpan = Math.round(duration.valueOf() * colCount)
    const colEndIndex = currentCol + colSpan - 1
    for (let i = currentCol; i <= colEndIndex; i++) {
      this.track.measure.cols[i].push(this)
    }
    this.cols = {
      from: currentCol,
      to: colEndIndex,
      span: colSpan
    }
  }

  extendLayout(width) {
    this.set('extendedWidth', width)
    this.set('measuredWidth', this.measuredWidth + width)
  }

  updateLayout() {
    this.set('minWidth', this.measuredWidth)
    if (this.track.measure.isStrict) {
      this.set('measuredWidth', this.track.measure.colWidth * this.cols.span)
    } else {
      this.set('measuredWidth', 0)
      const from = this.cols.from
      const to = this.cols.to
      for (let colIndex = from; colIndex <= to; colIndex++) {
        const col = this.track.measure.cols[colIndex]
        this.set('measuredWidth', this.measuredWidth + col.width)
      }
    }
  }

  layoutCrossAxis() {
    // TO OVERRIDE
  }

  //
  // STAFF COMPOSER ============================================================
  //

  setAsLastOfRow() {
    this.isLastOfRow = true
  }

  layoutMainAxis(posX) {
    const track = this.track
    const flex = this.track.measure.row.flex
    const isLastOfMeasure = track.blocs.indexOf(this) == track.blocs.length - 1
    let width
    if (isLastOfMeasure) {
      const trackLength = track.before + track.measuredWidth * flex
      width = trackLength - posX
    } else {
      width = this.measuredWidth * flex
    }
    this.set('x', posX, true)
    this.set('width', width, true)
  }
  //
  // RENDERER ==================================================================
  //

  render(val) {
    var o = Object.assign(val || {}, {
      x: this.x,
      y: this.y || 0,
      transform: this.translate,
      class: this.class,
      ref: () => this
    })
    this.renderInfo = () => o
    return o
  }
}

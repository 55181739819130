import Sprite from './data/Sprite'

export default class Bar extends Sprite {
  constructor(track) {
    super()
    const type = track.measure.isLast ? 'end' : 'single'
    this.type = type
    this.svg = `#bar-${type}`
    this.png = `bar-${type}`
    this.track = track
  }
  evalLayout(styles) {
    this.set('measuredWidth', this.type === 'single' ? 1 : styles.bar.end.width)
    this.set('measuredHeight', styles.bar[this.type].height)
  }
  layoutCrossAxis() {
    this.set('y', 0)
    this.set('height', this.measuredHeight)
  }
  layoutMainAxis(posX) {
    // const isLastOfRow = this.track.measure.isLastOfRow
    // const lastBloc = this.track.blocs[this.track.blocs.length - 1]
    // const maxOffset = isLastOfRow ? 1 : styles.bar.offset
    // const widthBefore = Math.max(2, lastBloc.width - lastBloc.minWidth)
    // const offset = Math.min(widthBefore / 2, maxOffset)
    this.set('x', posX, true)
    this.set('width', this.measuredWidth)
  }
  render() {
    return {
      id: `bar-${this.track.code}`,
      x: this.x,
      svg: this.svg,
      png: this.png
    }
  }
}

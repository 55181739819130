import Sprite from './data/Sprite'

export default class Stem extends Sprite {
  constructor(note) {
    super()
    this.note = note
    this.isUp = false
    this.png = 'stem'
  }

  getPosXInTrack() {
    const note = this.note
    return note.x + this.x
  }

  preLayoutCrossAxis(stemY, stemHeight, stemUp) {
    this.set('y', stemY)
    this.set('height', stemHeight)
    this.isUp = stemUp
    this.direction = stemUp ? -1 : 1
  }

  layoutCrossAxisBeam(beam, styles) {
    const stemUp = this.note.stem.isUp
    //
    // Apply the offset of the beam ...
    //
    if (beam.depth === 0) {
      const offset = beam.offset
      if (stemUp) {
        this.set('y', this.y + offset * this.direction)
      }
      this.set('height', this.height + offset)
    }
    //
    // ... and add the space for beams
    //
    let gap = styles.beam.thickness + styles.beam.gap
    const spacer = gap * beam.maxDepth - styles.beam.offset
    if (stemUp) {
      this.set('y', this.y + spacer * this.direction)
    }
    this.set('height', this.height + spacer)
  }

  layoutCrossAxisNote(styles) {
    const headTop = this.note.getNoteTop(styles)
    const stemUp = headTop > styles.staff.middle
    let stemHeight = styles.stem.height
    let stemTop = headTop - Number((stemUp && stemHeight) || 0)
    let stemBottom = stemUp ? headTop : headTop + stemHeight
    if (stemUp) {
      stemTop = Math.min(styles.staff.middle, stemTop)
    } else {
      stemBottom = Math.max(styles.staff.middle, stemBottom)
      stemTop += styles.stem.offset
    }
    this.isUp = stemUp
    this.direction = stemUp ? -1 : 1
    this.set('height', stemBottom - stemTop - styles.stem.offset)
    this.set('y', stemTop)
  }

  layoutMainAxis(styles) {
    const headX = this.note.head.x
    const headWidth = this.note.head.width
    let stemX = headX
    if (this.isUp) {
      stemX += headWidth - styles.stem.thickness
    }
    this.set('x', stemX)
    this.set('thickness', styles.stem.thickness)
  }

  render() {
    return {
      id: `stem-${this.note.code}`,
      png: this.png,
      x: this.x,
      y: this.y,
      height: this.height,
      thickness: this.thickness
    }
  }
}

import Sprite from './data/Sprite'

export default class Flag extends Sprite {
  constructor(note) {
    super()
    this.note = note
    this.flagCount = Math.log(note.duration.metric.d) / Math.log(2)
    this.png = `flag-${Math.pow(2, this.flagCount) * 4}th`
    this.svg = `#${this.png}`
  }

  evalLayout(styles) {
    const flagId = `${Math.pow(2, this.flagCount) * 4}th`
    this.measuredWidth = styles.flag[flagId].width
  }
  layoutCrossAxis() {
    const stem = this.note.stem
    const flagTop = stem.isUp ? stem.y : stem.y + stem.height
    this.set('y', flagTop)
  }

  layoutMainAxis() {
    const flagX = this.note.stem.x
    this.set('x', flagX)
    this.transform = [this.translate]
    if (!this.note.stem.isUp) {
      this.transform.push('scale(1, -1)')
      this.scale = -1
    } else {
      this.scale = 1
    }
  }

  render() {
    return {
      id: `flag-${this.note.code}`,
      svg: this.svg,
      png: this.png,
      x: this.x,
      y: this.y,
      transform: this.transform,
      scale: this.scale
    }
  }
}

import cloneDeep from 'lodash/cloneDeep'

export default class Report {
  constructor(from) {
    //
    // SHEET PARSER
    //
    this.warnings = from ? cloneDeep(from.warnings) : []
    //
    // SHEET BUILDER
    //
    this.duration = {
      beat: from?.duration.beat || null,
      fragment: from?.duration.fragment || null,
      time: from?.duration.time || 0
    }
    this.hasLyric = from?.hasLyric || false
    this.hasMetric = from?.hasMetric || false
    this.hasHarmony = from?.hasHarmony || false
    this.hasPart = from?.hasPart || false
    this.hasNote = from?.hasNote || false
    this.voices = from?.voices || []
    //
    // getters
    //
    this.events = from?.events || (() => [])
    this.blocMap = from?.blocMap || (() => new Map())
    //
    // STAFF COMPOSER
    //
    this.viewFrame = {
      width: from?.viewFrame?.width || 0,
      height: from?.viewFrame?.height || 0
    }
  }

  log() {
    let { events, blocMap, ...result } = this
    result = cloneDeep(result)
    if (events) result.events = events()
    if (blocMap) result.blocMap = blocMap()
    return result
  }
}

import Sprite from './data/Sprite'

export default class TimeSignature extends Sprite {
  constructor(track) {
    super()
    const metric = track.measure.metric
    this.up = new Sprite()
    this.up.svg = `#time-signature-${metric.beat}`
    this.up.png = `time-signature-${metric.beat}`

    this.down = new Sprite()
    this.down.svg = `#time-signature-${metric.note}`
    this.down.png = `time-signature-${metric.note}`

    this.track = track
  }
  evalLayout(styles) {
    const metric = this.track.measure.metric
    const upWidth = styles.time['signature-' + metric.beat].width
    const downWidth = styles.time['signature-' + metric.note].width
    this.set('upWidth', upWidth)
    this.set('downWidth', downWidth)
    this.set('measuredWidth', Math.max(upWidth, downWidth))
    this.set('measuredHeight', styles.staff.height)
  }
  layoutCrossAxis(styles) {
    this.down.set('y', 2 * styles.staff.unit)
  }
  layoutMainAxis(posX) {
    this.up.set('x', (this.measuredWidth - this.upWidth) / 2 + posX)
    this.down.set('x', (this.measuredWidth - this.downWidth) / 2 + posX)
    this.set('width', this.measuredWidth)
  }
  render() {
    return {
      up: {
        id: `time-up-${this.track.code}`,
        x: this.up.x,
        svg: this.up.svg,
        png: this.up.png
      },
      down: {
        id: `time-down-${this.track.code}`,
        x: this.down.x,
        y: this.down.y,
        svg: this.down.svg,
        png: this.down.png
      }
    }
  }
}

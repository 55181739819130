import Sprite from './data/Sprite'

export default class Extra extends Sprite {
  constructor(note, up, count) {
    super()
    this.note = note
    this.up = up
    this.count = count
  }

  evalLayout(styles) {
    const headWidth = this.note.head.measuredWidth
    const staffUnit = styles.staff.unit
    const height = this.count * staffUnit
    this.set('measuredWidth', styles.extra.overflow * 2 + headWidth)
    this.set('measuredHeight', height)
  }

  layoutCrossAxis(styles) {
    if (this.up) {
      this.set('y', -this.measuredHeight)
    } else {
      this.set('y', styles.staff.height - styles.staff.line.height)
    }
    this.set('height', this.measuredHeight)
    this.set('gutter', styles.staff.unit)
    this.set('thickness', styles.extra.thickness)
  }

  layoutMainAxis(styles) {
    const headX = this.note.head.x
    this.set('x', headX - styles.extra.overflow)
    this.set('width', this.measuredWidth)
  }

  render() {
    const lines = []
    for (let i = 0; i < this.count; i++) {
      lines.push({
        id: `extra-${this.note.code}-${i}`,
        y: this.y + this.gutter * (i + 1)
      })
    }
    return {
      transform: this.translate,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      count: this.count,
      thickness: this.thickness,
      gutter: this.gutter,
      png: 'extra',
      lines
    }
  }
}

import Container from './data/Container'
import Sprite from './data/Sprite'

export default class Tuplet extends Container {
  constructor(group, root, track) {
    super()
    this.value = group.tuplet.n
    this.root = root
    this.beam = null
    this.text = new Sprite()
    this.id = `tuplet-${track.code}-${track.tuplets.length}`
  }

  linkToBeam() {
    const firstBloc = this[0]
    const lastBloc = this[this.length - 1]
    const firstBeam = firstBloc.beams && firstBloc.beams[0]
    const lastBeam = lastBloc.beams && lastBloc.beams[0]
    if (firstBeam && firstBeam === lastBeam) {
      this.beam = firstBeam.root || firstBeam
    }
  }

  layoutCrossAxis(styles) {
    let layout = { isUp: false, height: 0, y: 0, text: 0 }
    if (this.beam) {
      this.layoutCrossAxisWithBeam(layout, styles)
      this.text.baseline = !layout.isUp ? 'text-before-edge' : 'text-after-edge'
      this.text.offsetY = layout.isUp ? -styles.tuplet.textHeight : 0
      this.text.offsetY += styles.tuplet.beamPadding * (layout.isUp ? -1 : 1)
    } else {
      this.layoutCrossAxisWithoutBeam(layout, styles)
      this.text.baseline = 'middle'
      this.text.offsetY = -Math.floor(styles.tuplet.textHeight / 2)
    }
    //
    // Update track overflow
    //
    const track = this[0].track
    let overflow = layout.y + layout.text + this.text.offsetY
    // if (layout.isUp) {
    //   overflow -= styles.tuplet.textHeight
    // }
    track.updateOverflow(overflow, styles.tuplet.textHeight)
    //
    // Set properties
    //
    this.up = layout.isUp
    this.set('y', layout.y)
    this.set('height', layout.height)
    this.text.set('y', layout.text)
  }

  layoutCrossAxisWithBeam(layout, styles) {
    let y = this.beam.y
    if (!this.beam.stemUp) {
      y += styles.beam.thickness
    }
    layout.y = y
    layout.isUp = this.beam.stemUp
    layout.height = Math.abs(this.beam.measuredHeight)
    layout.text = this.beam.measuredHeight / 2
  }

  layoutCrossAxisWithoutBeam(layout, styles) {
    let average = 0
    const bound = { top: Infinity, bottom: 0 }
    //
    // Eval the vertical bound of all heads of the tuplet
    //
    this.forEach((bloc) => {
      if (bloc.head.isRest) return
      const headTop = bloc.head.y
      const stemTop = bloc.stem.y
      const headBottom = headTop + bloc.head.height
      const stemBottom = stemTop + bloc.stem.height
      if (bloc.stem.isUp) {
        bound.top = Math.min(bound.top, stemTop)
        bound.bottom = Math.max(bound.bottom, headBottom)
      } else {
        bound.top = Math.min(bound.top, headTop)
        bound.bottom = Math.max(bound.bottom, stemBottom)
      }
      average += headTop + bloc.head.height / 2
    })
    //
    // Direction of the tuplet will be the closest of the staff
    //
    average /= this.length
    const tupletUp = average > styles.staff.middle
    //
    // The tuplet must not intersect the staff
    //
    if (tupletUp) {
      layout.y = Math.min(0, bound.top) - styles.tuplet.thickness
    } else {
      layout.y = Math.max(styles.staff.height, bound.bottom)
      layout.y += styles.tuplet.thickness
    }
    //
    // Eval the height ()
    //
    layout.height = 0
    layout.isUp = tupletUp
  }

  layoutMainAxis(rootTupletSkew, styles) {
    let layout = { width: 0, x: 0 }
    if (this.beam) {
      this.layoutMainAxisWithBeam(layout)
    } else {
      this.layoutMainAxisWithoutBeam(layout, rootTupletSkew, styles)
    }
    //
    // Set main frame
    //
    this.text.set('offsetX', -Math.floor(styles.tuplet.textWidth / 2))
    this.set('x', layout.x)
    this.text.set('x', layout.width / 2, true)
  }

  layoutMainAxisWithBeam(layout) {
    layout.x = this.beam.x
    layout.width = this.beam.width
    // layout.skew = this.beam.angle
  }

  layoutMainAxisWithoutBeam(layout, rootSkew, styles) {
    const firstBloc = this[0]
    const lastBloc = this[this.length - 1]
    const firstX = firstBloc.x
    const lastX = lastBloc.stem.getPosXInTrack() + styles.stem.thickness
    layout.width = lastX - firstX
    layout.x = firstX
    //
    // Eval beam skew
    //
    if (!this.root) {
      layout.skew = Math.atan2(this.height, layout.width)
      layout.skew *= -180 / Math.PI
    } else {
      layout.skew = rootSkew
    }
    //
    // Set bars
    //
    const textWidth = styles.tuplet.textWidth + styles.tuplet.textPadding * 2
    const tupletDirection = this.up ? -1 : 1
    const padding = 0
    let barW = (layout.width - textWidth) / 2
    let barH = (styles.tuplet.thickness / 2) * -tupletDirection
    barH = Math.round(barH)
    barW = Math.round(barW)

    this.bars = new Sprite()
    this.left = new Sprite()
    this.right = new Sprite()
    this.leftEnd = new Sprite()
    this.rightEnd = new Sprite()
    this.bars.set('width', barW)
    this.bars.set('height', Math.abs(barW))
    this.bars.transform = `skewY(${layout.skew})`

    this.left.path = `M 1,${barH} L 1,0 L ${barW},0`
    this.left.transform = `translate(0,${padding})`

    this.right.path = `M 0,0 L ${barW},0 L ${barW}, ${barH} `
    this.right.transform = `translate(${barW + textWidth},${padding})`
    this.right.x = barW + textWidth

    this.rightEnd.x = this.right.x + this.bars.width - 1
    this.leftEnd.y = !this.up ? barH : 0
    this.rightEnd.y = !this.up ? barH : 0

    this.left.y = this.right.y = 0
  }

  render() {
    var r = {}
    r.x = this.x
    r.y = this.y
    r.transform = this.translate
    r.value = this.value
    if (!this.beam) {
      r.bars = {
        height: this.bars.height,
        width: this.bars.width,
        transform: this.bars.transform
      }
      r.left = {
        id: `${this.id}-left`,
        png: 'tuplet-h',
        path: this.left.path,
        transform: this.left.transform,
        y: this.left.y
      }
      r.leftEnd = {
        id: `${this.id}-left-end`,
        png: 'tuplet-v',
        y: this.leftEnd.y
      }
      r.right = {
        id: `${this.id}-right`,
        png: 'tuplet-h',
        path: this.right.path,
        transform: this.right.transform,
        x: this.right.x,
        y: this.right.y
      }
      r.rightEnd = {
        id: `${this.id}-right-end`,
        png: 'tuplet-v',
        x: this.rightEnd.x,
        y: this.rightEnd.y
      }
    }
    if (this.text) {
      r.text = {
        id: `${this.id}-text`,
        png: `tuplet-${this.value}`,
        x: this.text.x + this.text.offsetX,
        y: this.text.y + this.text.offsetY,
        baseline: this.text.baseline
      }
    }
    return r
  }
}

import Anchor from './Anchor'
import Label from './Label'

export default class Lyric extends Label {
  constructor(track, event, context, raw) {
    super(track, event, context, raw)
    this.type = 'lyric'
    this.isLyric = true
    this.id = `lyric-${this.code}`
    this.canBeExtended = true
    raw = this.raw
    if (raw.length > 1) {
      const rawAnchor = raw[raw.length - 1]
      if (['~', '-', '_'].indexOf(rawAnchor) > -1) {
        this.hasAnchor = true
        this.rawAnchor = rawAnchor
        this.rawWithoutAnchor = raw.slice(0, -1)
      }
    }
    this.rawMeasure = (this.hasAnchor ? this.rawWithoutAnchor : this.raw) || '-'
  }

  //
  // STAFF BUILDER =============================================================
  //

  shouldMerge() {
    const last = this.track.blocs[this.track.blocs.length - 1]
    if (last && last.rawAnchor === '_' && this.isEmpty) {
      this.hasAnchor = true
      this.rawAnchor = '_'
    }
    if (this.isEmpty && last && last.rawAnchor != '_') {
      return { to: last }
    }
  }

  //
  // STAFF MEASURER ============================================================
  //

  evalLayout(currentCol, measurer, styles, last) {
    super.evalLayout(currentCol, measurer, styles, last)
    if (this.hasAnchor) {
      this.increase('measuredWidth', styles.lyric.anchor)
    } else {
      this.increase('measuredWidth', styles.lyric.gutter)
    }
  }

  updateLayout(options) {
    super.updateLayout(options)
    //
    // Overwrite the label and create anchor
    //
    let text = this.raw
    let next = this.track.blocs[this.indexInTrack + 1]
    if (!next) {
      const nextMeasure = this.track.measure.next
      if (nextMeasure) {
        next = nextMeasure.tracks[this.track.indexInMeasure].blocs[0]
      }
    }

    if (
      this.rawAnchor === '-' ||
      this.rawAnchor === '~' ||
      (this.rawAnchor === '_' && next?.isEmpty)
    ) {
      this.anchor = new Anchor(this)
    }

    if (!this.isEmpty && !this.isRest) {
      text = this.rawWithoutAnchor || this.raw
    } else {
      text = ''
    }

    this.label = text
  }

  layoutCrossAxis(styles) {
    if (this.anchor) {
      this.anchor.layoutCrossAxis(styles)
    }
  }

  //
  // STAFF COMPOSER ============================================================
  //

  layoutCrossAxisBeforeTrack(styles) {
    this.text.y = styles.lyric.offsetText
  }

  layoutMainAxis(posX, styles) {
    super.layoutMainAxis(posX, styles)
    if (this.anchor) {
      this.anchor.layoutMainAxis(styles)
    }
  }

  //
  // RENDERER ==================================================================
  //

  render() {
    let r = {}
    if (this.anchor) {
      r.anchor = this.anchor.render()
    }
    return super.render(r)
  }
}

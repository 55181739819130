/**
 * Created in : 'sheet-builder'
 * Ancestors : 'Fragment'
 * Children : 'Event'
 */
export default class Beat extends Array {
  constructor() {
    super()
    this.index = NaN
  }
}

import Accidental from './Accidental'
import Bloc from './Bloc'
import Extra from './Extra'
import Flag from './Flag'
import Head from './Head'
import Dot from './Dot'
import Stem from './Stem'
import Sprite from './data/Sprite'

const DOT_DEPTH_RATIOS = [2, 6, 14]

const staffLineIndex = {
  min: Head.lineIndexFromPitch('e', 4),
  max: Head.lineIndexFromPitch('f', 5)
}

export default class Note extends Bloc {
  constructor(track, event, context, raw) {
    super(track, event, context, raw)
    this.group = null
    this.isNote = true
    this.group = context.group
    this.bound = new Sprite()
    this.id = `note-${this.code}`
  }
  //
  // STAFF BUILDER =============================================================
  //

  build(currentHead) {
    //
    // Create the head
    //
    let head = (this.head = new Head(this, currentHead))
    //
    // Accidental
    //
    if (head.accidental && !this.tie) {
      this.accidental = new Accidental(this, head.accidental)
    }
    //
    // Extras lines
    //
    if (!head.isRest) {
      const headIndex = head.lineIndex.absolute
      const upperLineIndex = staffLineIndex.max.absolute
      const lowerLineIndex = staffLineIndex.min.absolute
      let upperExtra = Math.floor(Math.max(0, headIndex - upperLineIndex + 1))
      let lowerExtra = Math.floor(Math.max(0, lowerLineIndex - headIndex))
      if (upperExtra || lowerExtra) {
        const up = upperExtra > 0
        const count = upperExtra || lowerExtra
        this.extra = new Extra(this, up, count)
      }
    }
  }

  shouldMerge() {
    const current = this
    const last = this.track.blocs[this.track.blocs.length - 1]
    //
    // Merge only empty bloc and if bloc is not the first of the measure
    //
    if (current.raw !== '-' || !last) return
    //
    // We cannot merge a tuplet with another tuplet
    //
    if (this.group && this.group.tuplet && this.group !== last.group) {
      return
    }
    //
    // Check if the note will be dotted (in this case )
    //
    const lastOnBeat = last.position.beat.d === 1
    const currentDuration = current.duration.beat
    const lastDuration = last.duration.beat
    const next = this.track.blocs[this.indexInTrack + 1]
    const nextNotEmpty = !next || next.raw != '-'
    if (nextNotEmpty) {
      const dotDepth = 2
      for (var i = 0; i < dotDepth; i++) {
        const depth = DOT_DEPTH_RATIOS[i]
        const isHalf = lastDuration.div(depth).equals(currentDuration)
        // const isDouble = lastDuration.mul(depth).equals(currentDuration)
        if (isHalf /*|| isDouble*/) {
          return { withDot: true, depth: i, to: last }
        }
      }
    }
    //
    // Bloc of one beat is merged if the last one is on the beat
    //
    const currentIsBeat = current.duration.beat.d === 1
    if (lastOnBeat && currentIsBeat) {
      return { to: last }
    }
    //
    // If previous has the same duration (into a beat)
    //
    const isSameBeat = current.beatIndex === last.beatIndex
    const durationUnchanged = lastDuration.equals(currentDuration)
    if (isSameBeat && durationUnchanged) {
      return { to: last }
    }
  }

  addDot(depth) {
    this.dot = new Dot(this, depth)
  }

  addBeam(beam) {
    if (!this.beams) this.beams = []
    if (!this.stem) this.stem = new Stem(this)
    this.beams.push(beam)
  }

  addStem() {
    const duration = this.duration.metric.valueOf()
    const hasStem = !this.head.isRest && duration < 4
    if (hasStem) {
      this.stem = new Stem(this)
    }
  }

  addFlag() {
    if (!this.flag) {
      this.flag = new Flag(this)
    }
  }

  addTie(tie) {
    this.tie = tie
  }

  getNoteTop(styles) {
    const staffUnit = styles.staff.unit
    const staffTop = styles.staff.top
    return staffTop - this.head.lineIndex.absolute * staffUnit
  }

  //
  // STAFF MEASURER ============================================================
  //

  evalLayout(currentCol, styles, last) {
    let before = styles.note.padding
    //
    // Accidental (before)
    //
    if (this.accidental) {
      this.accidental.evalLayout(styles)
      if (last) {
        last.extendLayout(this.accidental.measuredWidth - styles.note.padding)
      }
    }
    //
    // Head
    //
    this.head.evalLayout(styles)
    let width = this.head.measuredWidth
    //measuredWidth
    // Flag (after)
    //
    let after = styles.note.padding
    if (this.flag) {
      this.flag.evalLayout(styles)
      after = Math.max(this.flag.measuredWidth, after)
    }
    //
    // Dot (after)
    //
    if (this.dot) {
      this.dot.evalLayout(styles)
      after = Math.max(this.dot.measuredWidth, after)
    }
    //
    // Extras
    //
    if (this.extra) {
      this.extra.evalLayout(styles)
    }
    //
    // Measures
    //
    let measuredWidth = before + width + after
    // const minWidth = this.isLastOfMeasure + styles.note
    //   ? styles.note.minLastWidth
    //   : styles.note.minWidth
    // measuredWidth = Math.max(minWidth, measuredWidth)
    this.set('measuredWidth', measuredWidth)
    this.bound.set('width', this.measuredWidth + styles.bound.padding * 2)
    this.set('measuredHeight', styles.staff.height)
    super.evalLayout(currentCol)
  }

  layoutCrossAxis(styles) {
    //
    // Accidental
    //
    if (this.accidental) {
      this.accidental.layoutCrossAxis(styles)
    }
    //
    //  Head
    //
    this.head.layoutCrossAxis(styles)
    //
    // Dot
    //
    if (this.dot) {
      this.dot.layoutCrossAxis(styles)
    }
    //
    // Extras (lines)
    //
    if (this.extra) {
      this.extra.layoutCrossAxis(styles)
    }
  }

  layoutCrossAxisAfter(styles) {
    //
    // Content
    //
    let contentY = this.head.y
    let contentHeight = this.head.height
    // if (this.stem) {
    //   if (this.stem.isUp) {
    //     contentY = this.stem.y
    //   }
    //   contentHeight = this.stem.height + contentHeight / 2
    // }
    contentY -= styles.bound.padding
    contentHeight += styles.bound.padding * 2
    this.bound.set('y', contentY)
    this.bound.set('height', contentHeight, true)
  }

  //
  // STAFF COMPOSER ============================================================
  //

  evalBefore() {
    return this.accidental?.measuredWidth || 0
  }

  layoutMainAxis(posX, styles) {
    let innerPosX = styles.note.padding
    //
    // Accidental
    //
    if (this.accidental) {
      this.accidental.layoutMainAxis(styles)
      // if (this.isFirstOfMeasure) {
      //   innerPosX += this.accidental.width
      // }
    }
    //
    //  Head
    //
    this.head.layoutMainAxis(innerPosX, styles)
    innerPosX += this.head.width
    //
    // Dot
    //
    if (this.stem) {
      this.stem.layoutMainAxis(styles)
    }
    //
    // Flag
    //
    if (this.flag) {
      this.flag.layoutMainAxis(styles)
    }
    //
    // Dot
    //
    if (this.dot) {
      this.dot.layoutMainAxis(innerPosX, styles)
    }
    //
    // Extras (lines)
    //
    if (this.extra) {
      this.extra.layoutMainAxis(styles)
    }

    this.bound.set('x', -styles.bound.padding)

    super.layoutMainAxis(posX, styles)
  }

  //
  // RENDERER ==================================================================
  //

  render() {
    const r = {}
    r.id = this.id
    r.isNote = true
    if (this.extra) {
      r.extra = this.extra.render()
    }
    if (this.stem) {
      r.stem = this.stem.render()
    }
    if (this.accidental) {
      r.accidental = this.accidental.render()
    }

    r.head = this.head.render()

    if (this.flag) {
      r.flag = this.flag.render()
    }
    if (this.dot) {
      r.dot = this.dot.render()
    }
    if (this.beams) {
      r.beams = this.beams.map((beam) => beam.id)
    }
    if (this.tie) {
      r.tie = this.tie.id
    }

    r.bound = {
      x: this.bound.x,
      y: this.bound.y,
      width: this.bound.width,
      height: this.bound.height
    }

    return super.render(r)
  }
}

import Sprite from './data/Sprite'

export default class Accidental extends Sprite {
  constructor(note, raw) {
    super()
    let alt = 0
    if (raw === '#') alt = 1
    if (raw === 'b') alt = -1
    if (raw === '!') alt = 0

    let altName
    if (alt > 0) altName = 'sharp'
    else if (alt < 0) altName = 'flat'
    else altName = 'neutral'

    this.note = note
    this.alt = alt
    this.name = altName
    this.svg = `#alt-${this.name}`
    this.png = `alt-${this.name}`
  }

  evalLayout(styles) {
    const width = styles.alt[this.name].width + styles.accidental.gutter
    const height = styles.alt[this.name].height
    this.set('measuredWidth', width)
    this.set('measuredHeight', height)
  }

  layoutCrossAxis(styles) {
    const staffUnit = styles.staff.unit
    const headLineIndex = this.note.head.lineIndex.absolute
    let altLineIndex = headLineIndex + 1.5
    const top = styles.staff.top - altLineIndex * staffUnit
    this.set('y', top)
    this.note.track.updateOverflow(top, this.measuredHeight)
  }

  layoutMainAxis(styles) {
    // if (!this.note.isFirstOfMeasure) {
    this.set('x', -this.measuredWidth + styles.note.padding)
    // }
    this.set('width', this.measuredWidth)
  }

  render() {
    return {
      id: `alt-${this.note.code}`,
      svg: this.svg,
      png: this.png,
      y: this.y,
      x: this.x
    }
  }
}

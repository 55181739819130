export default class Sprite {
  constructor(obj) {
    if (obj) {
      for (var name in obj) {
        this.set(name, obj[name])
      }
    }
  }
  get translate() {
    return `translate(${this.x || 0},${this.y || 0})`
  }
  get t() {
    return this.translate
  }
  set(prop, value, round) {
    if (isNaN(value)) {
      throw new Error(`${value} is not a valid ${prop} value`)
    }
    if ((prop === 'height' || prop === 'width') && value < 0) {
      throw new Error(`${value} is note a valid ${prop} value`)
    }
    this[prop] = round ? Math.round(value) : value
  }

  increase(prop, value, round) {
    this.set(prop, this[prop] + value, round)
  }

  freeze(name) {
    Object.defineProperty(this, name, { configurable: false, writable: false })
  }
}

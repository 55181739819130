import { chordParserFactory, chordRendererFactory } from 'chord-symbol'

import Sprite from './data/Sprite'
import Label from './Label'

const parseChord = chordParserFactory()

export default class Chord extends Label {
  constructor(track, event, context, raw) {
    super(track, event, context, raw)
    if (raw != '-') {
      const tune = track.measure.tune
      const transpose = tune.transpose
      const renderChord = chordRendererFactory({
        transposeValue: transpose,
        useShortNamings: true
      })
      const chord = parseChord(raw)
      this.label = renderChord(chord) || '???'
    }

    this.type = 'chord'
    this.isChord = true
    this.id = `chord-${this.code}`
    this.background = new Sprite()
  }

  //
  // STAFF BUILDER =============================================================
  //

  shouldMerge() {
    const current = this
    const last = this.track.blocs[this.track.blocs.length - 1]
    //
    // Merge only empty bloc and if bloc is not the first of the measure
    //
    if (current.raw === '-' && last) {
      return { to: last }
    }
  }

  evalLayout(currentCol, measurer, styles, last) {
    super.evalLayout(currentCol, measurer, styles, last)
    this.increase('measuredHeight', styles.chord.marginBottom)
  }

  //
  // STAFF COMPOSER ============================================================
  //

  layoutCrossAxisBeforeTrack(styles) {
    this.text.y = styles.chord.offsetText
  }

  layoutMainAxis(posX, styles) {
    super.layoutMainAxis(posX, styles)
    this.background.color = styles.chord.color
    if (this.bound) {
      this.background.set('x', this.bound.x)
      this.background.set('y', this.bound.y)
      this.background.set('width', this.bound.width)
      this.background.set('height', this.bound.height)
    }
  }

  //
  // RENDERER ==================================================================
  //

  render(r) {
    if (!r) r = {}
    r.id = this.id
    if (this.label) {
      r.label = {
        value: this.label,
        x: this.text.x,
        y: this.text.y || 0,
        style: this.type
      }
    }
    r.background = {
      x: this.background.x,
      y: this.background.y,
      width: this.background.width,
      height: this.background.height,
      color: this.background.color
    }
    return super.render(r)
  }
}

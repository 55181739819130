import Container from './data/Container'
import Head from './Head'

import Fraction from 'fraction.js'

const middleStaff = Head.lineIndexFromPitch('b', 4).absolute

export default class Beam extends Container {
  constructor(depth, root, index, track) {
    super()
    this.duration = new Fraction()
    this.depth = depth
    if (depth > 0) {
      this.root = root
    } else {
      this.subs = []
      this.maxDepth = 0
    }
    this.averageLineIndex = NaN
    this.lowest = null
    this.highest = null
    //
    // Flags
    //
    this.stemUp = false
    this.isRotated = false
    this.id = `beam-${track.code}-${index}`
  }

  evalStem() {
    if (this.depth === 0) {
      let highIndex, lowIndex
      this.averageLineIndex = 0
      this.forEach((bloc, blocIndex) => {
        const lineIndex = bloc.head.lineIndex.absolute
        this.averageLineIndex += bloc.head.lineIndex.absolute
        if (isNaN(lowIndex) || lowIndex > lineIndex) {
          lowIndex = lineIndex
          this.lowest = bloc
        }
        if (isNaN(highIndex) || highIndex < lineIndex) {
          highIndex = lineIndex
          this.highest = bloc
        }
        if (blocIndex < this.length - 1) {
          if (!this.duration) {
            this.duration = bloc.duration.measure
          } else {
            this.duration = this.duration.add(bloc.duration.measure)
          }
        }
      })
      this.averageLineIndex /= this.length
      this.stemUp = this.averageLineIndex < middleStaff
    } else {
      this.stemUp = this.root.stemUp
    }
  }

  evalLayout(styles) {
    this.set('thickness', styles.beam.thickness)
    //
    // Only for root beam (other are based on root)
    //
    if (this.depth) return

    const stemUp = this.stemUp
    const first = this[0]
    const last = this[this.length - 1]
    const firstNoteTop = first.getNoteTop(styles)
    const lastNoteTop = last.getNoteTop(styles)

    //
    // Eval beam height. The height of a beam is not its thickness.
    // It's the height of the bound (which is not the thickness because of
    // the skew)
    //
    let measuredHeight
    if (first.tuplets && first.tuplets[0].beam != this) {
      measuredHeight = 0
    } else {
      measuredHeight = lastNoteTop - firstNoteTop
      const beamDirection = Math.sign(measuredHeight)
      measuredHeight = Math.min(Math.abs(measuredHeight), styles.beam.maxHeight)
      measuredHeight *= beamDirection
      if (this.length > 2) {
        if (measuredHeight > 0) {
          const firstIsHighest = first === this.highest
          const lastIsLowest = last === this.lowest
          if ((stemUp && !firstIsHighest) || (!stemUp && !lastIsLowest)) {
            measuredHeight = 0
          }
        } else if (measuredHeight < 0) {
          const firstIsLowest = first === this.lowest
          const lastIsHighest = last === this.highest
          if ((stemUp && !lastIsHighest) || (!stemUp && !firstIsLowest)) {
            measuredHeight = 0
          }
        }
      }
    }
    // this.height = beamHeight
    //
    // Beams cols
    //
    const lastBefore = this[this.length - 2]
    const from = first.cols.from
    const to = lastBefore.cols.to
    let measuredWidth = 0
    for (let colIndex = from; colIndex <= to; colIndex++) {
      const col = first.track.measure.cols[colIndex]
      measuredWidth += col.width
    }
    this.set('measuredWidth', measuredWidth)
    this.set('measuredHeight', measuredHeight)

    this.direction = Math.sign(measuredHeight)
  }

  evalOffset(styles) {
    if (this.depth) return
    //
    // The offset allow each stem to fit the required min height
    //
    const stemUp = this.stemUp
    const stemDirection = stemUp ? -1 : 1
    let beamTop
    let beamFlex = 0
    this.offset = 0
    this.forEach((bloc, blocIndex) => {
      const headTop = bloc.getNoteTop(styles)
      //
      // Eval local beam position (intersection between beam and stem)
      //
      if (blocIndex === 0) {
        beamTop = headTop + styles.stem.height * stemDirection
      }
      const stemTop = beamTop + this.measuredHeight * beamFlex
      let stemY = stemUp ? stemTop : headTop
      if (!stemUp) {
        stemY += styles.stem.offset
      }
      let stemHeight = Math.abs(stemTop - headTop)
      stemHeight -= styles.stem.offset
      // bloc.stemUp = stemUp
      bloc.stem.preLayoutCrossAxis(stemY, stemHeight, stemUp)
      //
      // Eval offset required to allow stems to have a min height
      //
      let offset = Math.max(0, styles.stem.minHeight - bloc.stem.height)
      this.offset = Math.max(offset, this.offset)
      //
      // Update position of the bloc in the beam
      //
      beamFlex += bloc.measuredWidth / this.measuredWidth
    })
  }

  layoutCrossAxis(styles) {
    const stemDirection = this.stemUp ? -1 : 1
    const firstBloc = this[0]
    this.transform = []
    let beamY = firstBloc.stem.y
    if (!this.stemUp) {
      beamY += firstBloc.stem.height
    }
    let gap = this.depth * (styles.beam.thickness + styles.beam.gap)
    beamY -= gap * stemDirection
    if (!this.stemUp) {
      beamY -= styles.beam.thickness
    }
    if (this.length === 1) {
      const blocIndexInRoot = this.root.indexOf(firstBloc)
      if (blocIndexInRoot > 0) {
        this.isRotated = true
        beamY += styles.beam.thickness + styles.beam.gap
      }
    }
    this.set('y', beamY)
    // this.set('height', this.measuredHeight)
    const overflow = this.stemUp ? beamY : beamY
    firstBloc.track.updateOverflow(overflow, styles.staff.unit)
  }

  layoutMainAxis(rootBeamSkew, styles) {
    const first = this[0]
    const last = this[this.length - 1]
    const firstX = first.stem.getPosXInTrack()
    const lastX = last.stem.getPosXInTrack() + styles.stem.thickness
    //
    // Eval beam width
    //
    let beamWidth = first.head.width
    if (this.length > 1) {
      beamWidth = lastX - firstX
    }
    //
    // Eval beam skew
    //
    let beamSkew
    if (this.depth === 0) {
      let beamHeight = -this.measuredHeight
      beamSkew = Math.atan2(beamHeight, beamWidth)
      beamSkew *= -180 / Math.PI
      rootBeamSkew = beamSkew
    } else {
      beamSkew = rootBeamSkew
    }
    //
    // Set the frame
    //
    this.set('skew', beamSkew)
    this.set('x', firstX)
    this.set('width', beamWidth)
    this.transform = [`translate(${firstX},${this.y})`, `skewY(${beamSkew})`]
    if (this.isRotated) {
      this.transform.push('rotate(180)')
    }
  }

  render() {
    return {
      id: this.id,
      x: this.x,
      y: this.y,
      png: 'beam',
      width: this.width,
      transform: this.transform,
      thickness: this.thickness,
      skew: (this.skew * Math.PI) / 180,
      rotation: this.isRotated ? Math.PI : 0
    }
  }
}

import Sprite from './data/Sprite'

import Beam from './Beam'
import Tuplet from './Tuplet'
import Clef from './Clef'
import KeySignature from './KeySignature'
import TimeSignature from './TimeSignature'
import Bar from './Bar'
import Staff from './Staff'
import Tie from './Tie'
import Part from './Part'

/**
 * Created in : 'staff-builder'
 * Ancestors : 'Measure'
 * Children : 'Bloc'
 */
export default class Track extends Sprite {
  constructor(measure, context) {
    super()
    const cname = context.name
    const isNameUpper = cname.charAt(0).toUpperCase() + cname.slice(1)
    this[`is${isNameUpper}`] = true
    this.flowIndex = context.flowIndex
    if (this.isNote) {
      this.overflow = { top: 0, bottom: 0 }
    }
    //
    // Store all accidentals alterations of the track
    //
    this.alts = new Map()
    //
    // Ancestor
    //
    this.indexInMeasure = measure.tracks.length
    this.measure = measure
    this.code = `${measure.index}-${this.indexInMeasure}`
    this.id = `track-${this.code}`
    measure.tracks.push(this)
    //
    // Display
    //
    this.class = ['track', cname]
    if (this.isNote) {
      this.bar = new Bar(this)
      this.staff = new Staff(this)
    }
    this.background = new Sprite()
    this.measuredWidth = 0
    this.measuredHeight = 0
    this.extendedBefore = 0
    this.blocs = []
  }

  get raw() {
    return this.blocs.map((bloc) => bloc.raw).join(' ')
  }
  updateOverflow(y, h) {
    const max = this.measuredHeight
    if (y < 0) {
      this.overflow.top = Math.max(this.overflow.top, -y)
    }
    if (y + h > max) {
      this.overflow.bottom = Math.max(this.overflow.bottom, y + h - max)
    }
  }

  //
  // STAFF BUILDER =============================================================
  //

  mergeBlocs(lastOfType) {
    const blocs = Array.from(this.blocs)
    this.blocs.length = 0
    blocs.forEach((bloc) => {
      //
      // Check if merge is required
      //
      const mergeInfo = bloc.shouldMerge()
      if (mergeInfo) {
        //
        // Merge
        //
        mergeInfo.to.merge(bloc)
        if (mergeInfo.withDot) {
          mergeInfo.to.addDot(mergeInfo.depth)
        }
      } else {
        //
        // If raw is still empty, the bloc is binded to the last bloc of
        // the same type in the previous measure
        //
        if (bloc.raw === '-') {
          const lastBloc = lastOfType[this.indexInMeasure]
          if (bloc.isNote) {
            if (lastBloc) {
              let lastRaw = lastBloc.raw
              const offsetIndex = lastRaw.search(/[↓↑]/)
              if (offsetIndex != -1) {
                lastRaw = lastRaw.slice(0, offsetIndex)
              }
              bloc.raw = lastRaw
              if (lastRaw != 'r') {
                const tie = new Tie(bloc, lastBloc, this)
                bloc.addTie(tie)
                this.addTie(tie)
              }
            } else {
              bloc.raw = 'r'
            }
          } else if (bloc.isLyric) {
            // bloc.raw = ''
            // if (lastBloc && !lastBloc.raw.endsWith('~')) {
            //   lastBloc.raw += '~'
            // }
          }
        }
        //
        // Adding the bloc to the filtered track
        //
        this.blocs.push(bloc)
        lastOfType[this.indexInMeasure] = bloc
      }
    })
    //
    // As blocs as been filtered, their indexes have to be updated
    //
    this.blocs.forEach((bloc, blocIndex) => {
      bloc.updateIndex(blocIndex)
    })
  }

  addTie(tie) {
    if (!this.ties) {
      this.ties = []
    }
    this.ties.push(tie)
  }

  removeTie(tie) {
    const tieIndex = this.ties.indexOf(tie)
    this.ties.splice(tieIndex, 1)
    if (!this.ties.length) {
      delete this.ties
    }
  }

  buildBeams() {
    let beams = []
    let beamByDepth = []
    let rootBeam
    if (!this.isNote) return
    //
    // Create beams
    //
    this.blocs.forEach((bloc) => {
      if (bloc.position.beat.d === 1 || bloc.head.isRest) {
        beamByDepth.length = 0
      }
      if (bloc.head.isRest) return
      const duration = bloc.duration.metric.valueOf()
      let beamCount = 0
      if (duration < 1) {
        beamCount = 1 / bloc.duration.metric.valueOf()
        beamCount = Math.ceil(Math.log(beamCount) / Math.log(2))
      }
      for (var i = 0; i < beamCount; i++) {
        let beam = beamByDepth[i]
        if (!beam) {
          beam = new Beam(i, rootBeam, beams.length, this)
          beamByDepth[i] = beam
          beams.push(beam)
          if (i === 0) {
            rootBeam = beam
          } else {
            rootBeam.subs.push(beam)
            rootBeam.maxDepth = Math.max(rootBeam.maxDepth, i)
          }
        }
        bloc.addBeam(beam)
        beam.push(bloc)
      }
      beamByDepth.length = beamCount
    })
    //
    // Filter beams
    //
    this.beams = beams.filter((beam) => {
      //
      // The beam is valid except beam with only one note. In this case there
      // is no beam but some flag
      //
      const isValid = beam.root ? beam.root.length > 1 : beam.length > 1
      if (!isValid) {
        const bloc = beam[0]
        bloc.addFlag() //.flagCount = bloc.flagCount + 1 || 1
        delete bloc.beams
      }
      return isValid
    })
    //
    // Eval beams duration and stem direction
    //
    this.beams.forEach((beam) => {
      beam.evalStem()
    })
  }

  buildTuplets() {
    const pool = []
    //
    // Recursive group to tuplet builder
    //
    const groupToTuplet = (bloc, group) => {
      if (group.tuplet) {
        let poolItem = pool[pool.length - 1]
        while (poolItem && poolItem.group != group) {
          pool.pop()
          poolItem = pool[pool.length - 1]
        }
        if (!poolItem) {
          if (!this.tuplets) this.tuplets = []
          const tuplet = new Tuplet(group, pool.length && pool[0].tuplet, this)
          poolItem = { group, tuplet }
          pool.push(poolItem)
          this.tuplets.push(tuplet)
        }
        poolItem.tuplet.push(bloc)

        bloc.addTuplet(poolItem.tuplet)
      }
      if (group.parent) {
        groupToTuplet(bloc, group.parent)
      }
    }
    //
    // Build tuplet from bloc group
    //
    this.blocs.forEach((bloc) => {
      if (bloc.group) {
        groupToTuplet(bloc, bloc.group)
      }
      delete bloc.group
    })
    //
    // Eval tuplet depth
    //
    if (this.tuplets) {
      this.tuplets.forEach((tuplet) => {
        tuplet.linkToBeam()
      })
    }
  }

  //
  // STAFF MEASURER ============================================================
  //

  evalLayout(measurer, styles) {
    let currentCol = 0
    let measuredWidth = 0
    let measuredHeight = 0
    let last
    this.blocs.forEach((bloc) => {
      if (bloc.isNote) {
        bloc.evalLayout(currentCol, styles, last)
      } else {
        bloc.evalLayout(currentCol, measurer, styles, last)
      }
      currentCol += bloc.cols.span
      measuredWidth += bloc.measuredWidth
      measuredHeight = Math.max(measuredHeight, bloc.measuredHeight)
      last = bloc
    })
    this.set('measuredWidth', measuredWidth)
    this.set('measuredHeight', measuredHeight)
  }

  extendLayout(width) {
    this.set('extendedBefore', width)
  }

  updateLayout(options) {
    let measuredWidth = 0
    let last
    this.blocs.forEach((bloc) => {
      bloc.updateLayout(options, last)
      measuredWidth += bloc.measuredWidth
      last = bloc
    })
    this.set('measuredWidth', measuredWidth)
  }

  //
  // STAFF COMPOSER ============================================================
  //

  evalBeforeAndAfter(firstOfRow, styles) {
    //
    // Eval before width
    //
    let before = 0
    let after = 0
    let firstCount = 0
    if (this.isNote) {
      //
      // Clef
      //
      if (firstOfRow) {
        if (!this.clef) {
          this.clef = new Clef(this, styles)
          this.clef.evalLayout(styles)
        }
        before += this.clef.measuredWidth
        firstCount++
      } else {
        this.clef = null
      }
      //
      // Time Signature
      //
      if (
        !this.measure.anacrouse &&
        (firstOfRow || this.measure.metricChanged)
      ) {
        if (!this.timeSignature) {
          this.timeSignature = new TimeSignature(this)
          this.timeSignature.evalLayout(styles)
        }
        before += this.timeSignature.measuredWidth
        firstCount++
      } else {
        this.timeSignature = null
      }
      //
      // Key Signature
      //
      if (firstOfRow || this.measure.tuneChanged) {
        if (this.measure.tune.alt != 0) {
          if (!this.keySignature) {
            this.keySignature = new KeySignature(this)
            this.keySignature.evalLayout(styles)
          }
          before += this.keySignature.measuredWidth
          firstCount++
        } else {
          //
          // TODO : add neutral
          //
          this.keySignature = null
        }
      } else {
        this.keySignature = null
      }
      if (firstOfRow && this.ties && this.ties[0].isSplitted) {
        before += styles.tie.before
      }
      //
      // Add padding and gap
      //
      if (firstCount) {
        before += styles.staff.before.padding.left
        before += styles.staff.before.padding.right
        before += (firstCount - 1) * styles.staff.before.gap
      }
      //
      // Eval the after width
      //
      this.bar.evalLayout(styles)
      after = this.bar.measuredWidth
    }
    //
    // Apply modifier from bloc
    //
    const firstBloc = this.blocs[0]
    if (firstBloc.evalBefore) {
      before += firstBloc.evalBefore(styles)
    }
    this.before = before
    this.after = after
  }

  linkToRow(firstOfRow) {
    if (this.ties) {
      this.ties.forEach((tie) => {
        //
        // We only care about ties on multiples measure and tie which was not
        // created form a split.
        //
        if (tie.isOnMultipleMeasures && !tie.splitFrom) {
          //
          // We split ties which are at the beginning of the row
          //
          if (firstOfRow && !tie.isSplitted) {
            tie.split()
          }
          //
          // We merge tie splitted if they are not at the beginning of the row
          //
          if (!firstOfRow && tie.isSplitted) {
            tie.merge()
          }
        }
      })
    }
  }

  layoutCrossAxis(posY, styles, trackIsEmpty) {
    const overflow = this.measure.row.overflows[this.indexInMeasure]
    let trackY = posY
    let measuredHeight = 0
    //
    // Blocs. Re eval measured height since we know if the track is Empty
    //
    this.blocs.forEach((bloc) => {
      if (bloc.layoutCrossAxisBeforeTrack) {
        const blocHeight = bloc.layoutCrossAxisBeforeTrack(styles, trackIsEmpty)
        measuredHeight = Math.max(measuredHeight, blocHeight)
      }
    })
    //
    // Apply the overflow
    //
    let trackHeight = measuredHeight || this.measuredHeight
    if (overflow) {
      trackHeight += overflow.top + overflow.bottom
      trackY += overflow.top
    }
    this.set('y', trackY)
    this.set('height', trackHeight)
    //
    // Background
    //
    this.background.set('y', overflow ? -overflow.top : 0)
    //
    // Staff
    //
    if (this.staff) {
      this.staff.layoutCrossAxis(styles)
    }
    //
    // Clef
    //
    if (this.clef) {
      this.clef.layoutCrossAxis(styles)
    }
    //
    // Time Signature
    //
    if (this.timeSignature) {
      this.timeSignature.layoutCrossAxis(styles)
    }
    //
    // Key Signature
    //
    if (this.keySignature) {
      this.keySignature.layoutCrossAxis(styles)
    }
    //
    // Bar
    //
    if (this.bar) {
      this.bar.layoutCrossAxis(styles)
    }
    //
    // Blocs
    //
    this.blocs.forEach((bloc) => {
      if (bloc.layoutCrossAxisAfterTrack) {
        bloc.layoutCrossAxisAfterTrack(styles, trackIsEmpty)
      }
    })
  }

  layoutMainAxis(styles) {
    let posX = this.before ? styles.staff.before.padding.left : 0
    // const flex = this.measure.row.flex
    // let width = this.measuredWidth * flex
    //
    // Clef
    //
    let beforeCount = 0
    if (this.clef) {
      this.clef.layoutMainAxis(posX, styles)
      posX += this.clef.width
      beforeCount++
    }
    //
    // Key Signature
    //
    if (this.keySignature) {
      if (beforeCount) posX += styles.staff.before.gap
      this.keySignature.layoutMainAxis(posX, styles)
      posX += this.keySignature.width
      beforeCount++
    }
    //
    // Time Signature
    //
    if (this.timeSignature) {
      if (beforeCount) posX += styles.staff.before.gap
      this.timeSignature.layoutMainAxis(posX)
      posX += this.timeSignature.width
      beforeCount++
    }
    //
    // Blocs
    //
    posX = this.before
    let last
    this.blocs.forEach((bloc) => {
      bloc.layoutMainAxis(posX, styles, last)
      posX += bloc.width
      last = bloc
    })
    //
    // Bar
    //
    if (this.bar) {
      this.bar.layoutMainAxis(posX, styles)
    }
    posX += this.after
    //
    // Beams
    //
    if (this.beams) {
      let rootBeamSkew
      this.beams.forEach((beam) => {
        beam.layoutMainAxis(rootBeamSkew, styles)
        if (!beam.depth) {
          rootBeamSkew = beam.skew
        }
      })
    }
    //
    // Tuplets
    //
    if (this.tuplets) {
      let rootTupletSkew
      this.tuplets.forEach((tuplet) => {
        tuplet.layoutMainAxis(rootTupletSkew, styles)
        if (!tuplet.root) {
          rootTupletSkew = tuplet.skew
        }
      })
    }
    //
    // Ties
    //
    if (this.ties) {
      this.ties.forEach((tie) => {
        tie.layoutMainAxis(posX, styles)
      })
    }
    //
    // Staff
    //
    if (this.staff) {
      this.staff.layoutMainAxis(posX)
    }
    //
    // Track
    //
    this.set('width', posX, true)
  }

  //
  // RENDERER ==================================================================
  //

  render() {
    const t = {}
    t.width = this.width
    t.height = this.height
    t.y = this.y
    t.id = this.id
    t.transform = this.translate
    t.class = this.class
    t.key = this.id
    t.before = this.before
    t.after = this.after
    t.background = {}
    t.background.y = this.background.y
    const firstBloc = this.blocs[0]
    if (firstBloc.background && firstBloc instanceof Part) {
      t.background.before = {
        id: `${this.id}-before`,
        y: firstBloc.background.y,
        height: firstBloc.background.height,
        width: this.before - this.extendedBefore,
        color: firstBloc.background.color
      }
      t.background.after = {
        id: `${this.id}-after`,
        x: this.width - this.after,
        y: firstBloc.background.y,
        height: firstBloc.background.height,
        width: this.after,
        color: firstBloc.background.color
      }
    }
    if (this.staff) {
      t.staff = this.staff.render()
    }
    if (this.clef) {
      t.clef = this.clef.render()
    }
    if (this.keySignature) {
      t.keySignature = this.keySignature.render()
    }
    if (this.timeSignature) {
      t.timeSignature = this.timeSignature.render()
    }

    t.blocs = this.blocs.map((bloc) => bloc.render())

    if (this.bar) {
      t.bar = this.bar.render()
    }

    if (this.beams) {
      t.beams = this.beams.map((beam) => beam.render())
    }
    if (this.tuplets) {
      t.tuplets = this.tuplets.map((tuplet) => tuplet.render())
    }
    if (this.ties) {
      t.ties = this.ties.map((tie) => tie.render())
    }
    return t
  }
}

import Sprite from './data/Sprite'

import Track from './Track'
import Chord from './Chord'
import Lyric from './Lyric'
import Note from './Note'
import Tune from './Tune'
import Part from './Part'

import Col from './data/Col'
import { getMinMultiple } from '../utils/tools-utils.js'

const blocClassByName = {
  lyric: Lyric,
  chord: Chord,
  note: Note,
  part: Part
}
/**
 * Created in : 'staff-builder'
 * Ancestors : 'Row'
 * Children : 'Track'
 */
export default class Measure extends Sprite {
  constructor(fragments, index, last, transpose, renderOptions) {
    super()
    const fragment = fragments[index]
    this.id = `measure-${index}`
    this.index = index
    this.tune = last?.tune || new Tune('c', transpose)
    this.breakline = false
    //
    // From fragment
    //
    this.beatCount = fragment.beatCount
    this.metric = fragment.metric
    //
    // Map which store all durations of each bloc of the measure. The unit is
    // in part of the mesure: 1/duration.measure
    //
    this.colSizes = new Map()
    //
    // Display
    //
    this.class = ['measure']
    this.measuredWidth = 0
    this.measuredHeight = 0
    this.tracks = []
    this.background = new Sprite()
    //
    // Flags
    //
    this.isFirst = index === 0
    this.isLast = index === fragments.length - 1
    this.metricChanged = false
    this.tuneChanged = false

    if (last) {
      last.next = this
      this.prev = last
      if (last.beatCount != this.beatCount) {
        this.metricChanged = true
        if (index === 1) {
          this.anacrouse = true
          last.metric = this.metric
        }
      }
    }
    this.fragment = fragment
    this.build(fragment, renderOptions)
  }

  get raws() {
    return this.tracks.map((track) => track.raw)
  }

  //
  // STAFF BUILDER =============================================================
  //

  build(fragment, renderOptions) {
    const trackByFlowIndex = new Map()
    const showLyric = renderOptions.indexOf('lyric') > -1
    const showChord = renderOptions.indexOf('harmony') > -1
    const showPart = renderOptions.indexOf('part') > -1

    fragment.forEach((beat) => {
      beat.forEach((event) => {
        let breaklineFlowIndex = -1
        // const blocInEvent = []
        event.forEach((context) => {
          const currentDuration = context.duration
          let raw = context.raw
          let cname = context.name
          const isLyric = cname === 'lyric'
          const isChord = cname === 'chord'
          const isNote = cname === 'note'
          const isTune = cname === 'tune'
          const isPart = cname === 'part'
          //
          // Check tune changes
          //
          if (isTune && raw != '-') {
            //
            // TODO : Tune is actually link to measure, but it should be link
            // to Track
            //
            this.tune = new Tune(raw, this.tune.transpose)
            this.tuneChanged = true
          }
          //
          // Abort other context than note, lyric or chord
          //
          if (!isNote && !isLyric && !isChord && !isPart) return
          //
          // Abort if not in renderOptions
          //
          if (isLyric && !showLyric) return
          if (isChord && !showChord) return
          if (isPart && !showPart) return
          //
          // Get or create the track
          //
          const flowIndex = context.flowIndex
          let track = trackByFlowIndex.get(flowIndex)
          if (!track) {
            track = new Track(this, context)
            trackByFlowIndex.set(flowIndex, track)
          }
          //
          // Extract breakline from raw data
          //
          if (breaklineFlowIndex === -1 && isNote) {
            breaklineFlowIndex = context.flowIndex
          }
          if (context.breakline) {
            if (breaklineFlowIndex === context.flowIndex) {
              this.breakline = true
            }
          }
          //
          // Creation of a new bloc
          //
          const blocClass = blocClassByName[cname]
          new blocClass(track, event, context, raw)
          // blocInEvent[context.flowIndex] = bloc
          // bloc.inEvent = blocInEvent
          //
          // Update the nb of cols required by the track.
          //
          let colsize = currentDuration.measure.d
          currentDuration.measure.valueOf()
          if (!this.colSizes.has(colsize)) {
            this.colSizes.set(colsize, true)
          }
        })
      })
    })
  }

  //
  // STAFF MEASURER ============================================================
  //

  evalLayout(options, styles, measurer) {
    //
    // Reset measured size
    //
    this.set('measuredHeight', 0)
    this.set('measuredWidth', 0)
    //
    // Eval cols
    //
    const colsizes = Array.from(this.colSizes, ([name]) => name)
    const colCount = getMinMultiple(colsizes)
    let minColWidth = (styles.measure.beatWidth * this.beatCount) / colCount
    this.isStrict = options.isStrict
    if (!this.isStrict) {
      let measureWidth = 0
      if (!options.isDense) {
        measureWidth = styles.measure.beatWidth * this.beatCount
      }
      const colWidth = measureWidth / colCount
      this.set('colWidth', colWidth)
    }
    this.cols = []
    for (let i = 0; i < colCount; i++) {
      this.cols[i] = new Col()
    }
    //
    // Eval height
    //
    this.tracks.forEach((track) => {
      track.evalLayout(measurer, styles)
      //
      //
      if (this.isStrict) {
        track.blocs.forEach((bloc) => {
          const colWidth = bloc.measuredWidth / bloc.cols.span
          minColWidth = Math.max(minColWidth, colWidth)
        })
      }
      this.increase('measuredHeight', track.measuredHeight)
    })

    if (this.isStrict) {
      this.set('colWidth', Math.ceil(minColWidth))
      this.set('measuredWidth', this.colWidth * colCount)
      // this.beatWidth = this.measuredWidth / this.beatCount
    }
  }

  updateLayout(options) {
    this.cols.forEach((col) => {
      col.width = this.colWidth
      if (!this.isStrict) {
        col.forEach((bloc) => {
          const requiredWidth = bloc.measuredWidth / bloc.cols.span
          col.width = Math.max(col.width, requiredWidth)
        })
        this.set('measuredWidth', this.measuredWidth + col.width)
      }
    })
    this.tracks.forEach((track) => {
      track.updateLayout(options)
    })
  }

  //
  // STAFF COMPOSER ============================================================
  //

  evalBeforeAndAfter(firstOfRow, styles) {
    this.before = 0
    this.after = 0
    this.tracks.forEach((track) => {
      // if (!track.isNote) return
      track.evalBeforeAndAfter(firstOfRow, styles)
      this.before = Math.max(track.before, this.before)
      this.after = Math.max(track.after, this.after)
    })
    this.tracks.forEach((track) => {
      track.before = this.before
      track.after = this.after
    })
  }

  linkToRow(row, firstOfRow) {
    this.isFirstOfRow = firstOfRow
    this.isLastOfRow = false
    this.row = row
    this.tracks.forEach((track) => {
      track.linkToRow(firstOfRow)
    })
  }

  setAsLastOfRow() {
    this.isLastOfRow = true
    this.tracks.forEach((track) => {
      if (track.isNote) {
        track.blocs[track.blocs.length - 1].setAsLastOfRow()
      }
    })
  }

  layoutCrossAxis(styles, hidden, empty) {
    let trackPosY = 0
    this.tracks.forEach((track, trackIndex) => {
      const trackIsHidden = hidden[trackIndex]
      const trackIsEmpty = empty[trackIndex]
      if (!trackIsHidden) {
        track.layoutCrossAxis(trackPosY, styles, trackIsEmpty)
        trackPosY += track.height
      }
    })
    this.set('height', trackPosY)
    this.background.set('height', trackPosY)
  }

  layoutMainAxis(posX, syles) {
    this.set('x', posX, true)
    let width = 0
    this.tracks.forEach((track) => {
      track.layoutMainAxis(syles)
      width = Math.max(width, track.width)
    })
    this.set('width', width)
    this.background.set('width', width)
    this.background.set('x', this.x)
  }

  //
  // RENDERER ==================================================================
  //

  render() {
    const tracks = []
    this.tracks.forEach((track, trackIndex) => {
      if (!this.row.hiddenTracks[trackIndex]) {
        tracks.push(track.render())
      }
    })

    return {
      id: this.id,
      class: this.class,
      key: this.id,
      transform: this.translate,
      x: this.x,
      width: this.width,
      tracks,
      background: {
        id: `${this.id}-background`,
        width: this.background.width,
        height: this.background.height,
        x: this.background.x
      }
    }
  }
}
